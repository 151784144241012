import React, { Component } from "react";
import RegisterPanel from "../components/Auth/RegisterPanel";
import {
  setRootElementClass,
  ROOT_CLASSNAMES,
} from "../components/utils/root-element-utils";

class Register extends Component {
  constructor() {
    super();
    setRootElementClass(ROOT_CLASSNAMES.auth);
  }

  render() {
    return (
      <div
        className="container-fluid"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <RegisterPanel />
      </div>
    );
  }
}

export default Register;
