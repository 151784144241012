import { UPDATE_DATA } from "../actions/types";

const initialState = {
  labels: [],
  data: [
    // [99, 11, 0, 11, 22, 44, 88, 99, 77, 88, 66, 22],
    // [11, 22, 33, 44, 55, 66, 77, 88, 99, 11, 22, 33],
    // [32, 45, 15, 8, 13, 26, 64, 11, 39, 15, 24, 17]
  ]
};

export default function(state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case UPDATE_DATA:
      return {
        state: payload
      };
    default:
      return state;
  }
}
