// export const HOST =
//   "http://earthstationmodular-web.i9kqpaq8cx.ca-central-1.elasticbeanstalk.com";
export const HOST = "https://www.earth-station-modular.com";
export const PORT = "";
//export const HOST = "http://localhost";
//export const PORT = ":5000";
export const API_ROOT = "/api";
export const API_URL = HOST + PORT + API_ROOT;
export const MAXIMUM_DATA_PER_REQUEST = 50;
export const JSON_HEADER_CACHABLE = {
  headers: {
    "Content-Type": "application/json",
  },
};
export const JSON_HEADER_NOCACHE = {
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
};

export const MESSAGE_SUCCESS = "SUCCESS";
export const MESSAGE_FAILURE = "FAILURE";
export const MESSAGE_ERROR = "ERROR";

export const GET_LATEST_RECORDS_ENDPOINT = API_URL + "/data/getLatestRecords";
export const GET_RANGE_RECORDS_ENDPOINT = API_URL + "/data/getRecords";

export const GET_SCHEME_ENDPOINT = API_URL + "/scheme/getScheme";
export const ADD_BLUEPRINT_ENDPOINT = API_URL + "/scheme/addBlueprint";
export const DELETE_BLUEPRINT_ENDPOINT = API_URL + "/scheme/deleteBlueprint";

export const LOGIN_ENDPOINT = API_URL + "/auth/login";
export const REGISTER_ENDPOINT = API_URL + "/auth/register";

export const LOAD_TASKS_ENDPOINT = API_URL + "/automation/getTasks";
export const ADD_TASK_ENDPOINT = API_URL + "/automation/addTask";
export const EDIT_TASK_ENDPOINT = API_URL + "/automation/editTask";
export const DELETE_TASK_ENDPOINT = API_URL + "/automation/deleteTask/";
