import { convertTimeFromGmtToLocal } from "./date-time-utils";

const datasetColors = [
  "#3f4045",
  "#eb9486",
  "#edb368",
  "#A846A0",
  "#23CE6B",
  "#565676",
  "#AD343E",
  "#090446",
  "#59a5d8",
  "#474b24",
  "#3f40FF",
  "#eb94AA",
  "#edAA68",
  "#A8EEA0",
  "#FFCE6B",
  "#CC5676",
  "#ADDD3E",
  "#09AA46",
  "#59aaf8",
  "#47a824"
];

const emptyDataset = {
  label: "",
  fill: false,
  data: [],
  backgroundColor: "",
  borderColor: "",
  pointHoverBorderColor: "",
  pointHoverBackgroundColor: "",
  borderWidth: 2,
  yAxisID: "y-axis-primary"
};

/* Generates a random number and chooses from the palette (Random Numbers might come out twice) */
/*const randomColorSelector = palette => {
  const max = datasetColors.length;
  const min = 0;
  const randomNumber = Math.floor(Math.random() * (+max - +min)) + min;
  palette.splice(randomNumber, 1);
  return {
    palette: palette,
    color: palette[randomNumber]
  };
};*/

const filterData = (rawLabels, rawData, selectedVariables) => {
  if (selectedVariables.length < 1) {
    return { labels: rawLabels, data: rawData };
  }
  const qualifiedLabels = [];
  const qualifiedData = [];
  rawLabels.forEach(label => {
    if (selectedVariables.includes(label)) {
      qualifiedLabels.push(label);
      qualifiedData.push(rawData[rawLabels.indexOf(label)]);
    }
  });

  return {
    labels: qualifiedLabels,
    data: qualifiedData
  };
};

const generateDatasets = (
  labels = [],
  data = [[]],
  timelinesAndContainers = []
) => {
  const datasets = [];
  let colorPalette = datasetColors.slice(0);
  labels.forEach((label, index) => {
    let inProgressDataset = emptyDataset;
    const datasetColor = colorPalette.pop();
    const currentDataset = [];
    data.forEach((currentData, currentIndex) => {
      if (currentData[index].value !== undefined) {
        currentDataset.push(currentData[index].value);
      } else {
        currentDataset.push(currentData[index]);
      }
    });

    inProgressDataset = {
      ...inProgressDataset,
      label: label,
      data: currentDataset,
      backgroundColor: datasetColor,
      borderColor: datasetColor,
      pointHoverBorderColor: datasetColor,
      pointHoverBackgroundColor: datasetColor
    };

    datasets.push(inProgressDataset);
  });

  return datasets;
};

const generateEmptyContainer = (labels, selectedVariables = []) => {
  const emptyTemplate = [];
  let iterator = 0;
  if (selectedVariables.length > 0) {
    const templateFieldLength = selectedVariables.length;
    for (iterator = 0; iterator < templateFieldLength; iterator++) {
      emptyTemplate.push("");
    }
  } else {
    const templateFieldLength = labels.length;
    for (iterator = 0; iterator < templateFieldLength; iterator++) {
      emptyTemplate.push("");
    }
  }
  return emptyTemplate;
};

const generateTimelineAndDataContainers = (
  dataset = [],
  labels,
  blueprints,
  selectedVariables = []
) => {
  if (dataset.length < 1) {
    return;
  }
  const timelines = [];
  const dataContainers = [];
  const emptyContainerTemplate = [
    ...generateEmptyContainer(labels, selectedVariables)
  ];

  dataset.forEach(data => {
    // const currentTimeline = data.date + " " + data.time;
    const currentTimeline =
      data.date + " " + convertTimeFromGmtToLocal(data.time);
    const indexInBlueprintsToReferenceInLabels = blueprints.indexOf(
      data.blueprintID
    );
    const parentLabel = labels[indexInBlueprintsToReferenceInLabels];
    if (selectedVariables.length > 0) {
      if (selectedVariables.indexOf(parentLabel) !== -1) {
        if (timelines.indexOf(currentTimeline) < 1) {
          timelines.push(currentTimeline);
          dataContainers.push([...emptyContainerTemplate]);
        }
      }
    } else {
      if (timelines.indexOf(currentTimeline) < 1) {
        timelines.push(currentTimeline);
        dataContainers.push([...emptyContainerTemplate]);
      }
    }
  });

  return {
    timelines: timelines,
    dataContainers: dataContainers
  };
};

const generateDataInContainers = (
  timelinesAndContainers,
  processedData,
  blueprints,
  labels,
  selectedVariables = []
) => {
  /* Check for empty arrays later on */
  const containers = [...timelinesAndContainers.dataContainers];
  const timelines = [...timelinesAndContainers.timelines];
  processedData.forEach(data => {
    if (data.length < 1) {
      return;
    }
    const indexInContainer = blueprints.indexOf(data[0].blueprintID);
    data.forEach((innerData, index) => {
      const currentTimeline =
        innerData.date + " " + convertTimeFromGmtToLocal(innerData.time);
      const indexInTimelines = timelines.indexOf(currentTimeline);
      const indexInBlueprintsToReferenceInLabels = blueprints.indexOf(
        innerData.blueprintID
      );
      const parentLabel = labels[indexInBlueprintsToReferenceInLabels];
      const indexInLabels = labels.indexOf(parentLabel);
      const indexInSelectedVariables = selectedVariables.indexOf(
        labels[indexInLabels]
      );

      if (selectedVariables.length > 0) {
        if (selectedVariables.indexOf(parentLabel) !== -1) {
          if (containers[indexInTimelines] !== undefined) {
            containers[indexInTimelines][indexInSelectedVariables] = innerData;
          }
        }
      } else {
        if (containers[indexInTimelines] !== undefined) {
          containers[indexInTimelines][indexInContainer] = innerData;
        }
      }
      // if (selectedVariables.length > 0) {
      //   if (selectedVariables.indexOf(parentLabel) !== -1) {
      //     if (containers[indexInTimelines] !== undefined) {
      //       containers[indexInTimelines][indexInContainer] = innerData;
      //     }
      //   }
      // } else {
      //   if (containers[indexInTimelines] !== undefined) {
      //     containers[indexInTimelines][indexInContainer] = innerData;
      //   }
      // }
    });
  });
  return containers;
};

export const generateChartData = (
  rawLabels = [],
  rawData = [],
  processedData = [[]],
  blueprints = [],
  selectedVariables = []
) => {
  const timelinesAndDataContainers = generateTimelineAndDataContainers(
    rawData,
    rawLabels,
    blueprints,
    selectedVariables
  );
  const filteredData = filterData(rawLabels, processedData, selectedVariables);
  const dataInContainers = generateDataInContainers(
    timelinesAndDataContainers,
    processedData,
    blueprints,
    rawLabels,
    selectedVariables
  );
  const datasets = generateDatasets(
    filteredData.labels,
    dataInContainers,
    timelinesAndDataContainers
  );

  const chartData = {
    labels: timelinesAndDataContainers.timelines,
    datasets: datasets
  };
  return chartData;
};

export const generateTableData = (
  rawLabels = [],
  rawData = [],
  processedData = [[]],
  blueprints = [],
  selectedVariables = []
) => {
  const filteredData = filterData(rawLabels, processedData, selectedVariables);
  const tableData = {
    labels: filteredData.labels,
    datasets: filteredData.data
  };
  return tableData;
};

export const sortedMockingObject = {
  blueprints: [
    "6f42ba74-508d-4157-9c10-0dce2441cabe",
    "60989fda-020c-4d48-bd0e-db375434e10a"
  ],
  labels: ["Weather", "Temperature"],
  data: [
    [
      {
        date: "2019-05-13",
        time: "08:19:03",
        blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
        value: 40
      },
      {
        date: "2019-05-13",
        time: "08:19:15",
        blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
        value: 80
      },
      {
        date: "2019-05-13",
        time: "08:19:21",
        blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
        value: 80
      },
      {
        date: "2019-05-13",
        time: "08:19:30",
        blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
        value: 70
      },
      {
        date: "2019-05-13",
        time: "08:19:40",
        blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
        value: 30
      },
      {
        date: "2019-05-13",
        time: "08:19:48",
        blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
        value: 100
      }
    ],
    [
      {
        date: "2019-05-13",
        time: "08:19:05",
        blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
        value: 50
      },
      {
        date: "2019-05-13",
        time: "08:19:15",
        blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
        value: 90
      },
      {
        date: "2019-05-13",
        time: "08:19:21",
        blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
        value: 90
      },
      {
        date: "2019-05-13",
        time: "08:19:30",
        blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
        value: 60
      },
      {
        date: "2019-05-13",
        time: "08:19:40",
        blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
        value: 45
      },
      {
        date: "2019-05-13",
        time: "08:19:48",
        blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
        value: 120
      }
    ]
  ]
};

export const rawMockingObject = {
  blueprints: {
    "6f42ba74-508d-4157-9c10-0dce2441cabe": {
      id: "6f42ba74-508d-4157-9c10-0dce2441cabe",
      name: "Weather",
      mandatory: true,
      frequency: "00:11:00",
      icon: "fas fa-random icon-button"
    },
    "60989fda-020c-4d48-bd0e-db375434e10a": {
      id: "60989fda-020c-4d48-bd0e-db375434e10a",
      name: "Temperature",
      mandatory: true,
      frequency: "00:11:00",
      icon: "fas fa-compress icon-button"
    }
  },
  data: [
    {
      date: "2019-05-13",
      time: "08:19:03",
      blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
      value: "40"
    },
    {
      date: "2019-05-13",
      time: "08:19:05",
      blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
      value: "50"
    },
    {
      date: "2019-05-13",
      time: "08:19:15",
      blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
      value: "90"
    },
    {
      date: "2019-05-13",
      time: "08:19:15",
      blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
      value: "80"
    },
    {
      date: "2019-05-13",
      time: "08:19:21",
      blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
      value: "90"
    },
    {
      date: "2019-05-13",
      time: "08:19:21",
      blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
      value: "80"
    },
    {
      date: "2019-05-13",
      time: "08:19:30",
      blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
      value: "60"
    },
    {
      date: "2019-05-13",
      time: "08:19:30",
      blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
      value: "70"
    },
    {
      date: "2019-05-13",
      time: "08:19:40",
      blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
      value: "30"
    },
    {
      date: "2019-05-13",
      time: "08:19:40",
      blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
      value: "45"
    },
    {
      date: "2019-05-13",
      time: "08:19:48",
      blueprintID: "6f42ba74-508d-4157-9c10-0dce2441cabe",
      value: "100"
    },
    {
      date: "2019-05-13",
      time: "08:19:48",
      blueprintID: "60989fda-020c-4d48-bd0e-db375434e10a",
      value: "120"
    }
  ]
};
