import { isBlank } from "./string-utils";
import {
  BLUEPRINT_TYPES,
  BLUEPRINT_TYPE_CATEGORIES,
} from "../Scheme/BlueprintTypes";

const validAgainstEmptyFields = (blueprint) => {
  const functionlessBlueprint = Object.entries(blueprint).filter(
    ([, value]) => {
      return typeof value !== "function";
    }
  );

  return (
    functionlessBlueprint.filter(([, value]) => {
      return !isBlank(value);
    }).length === functionlessBlueprint.length
  );
};

const validAgainstDuplicateBlueprint = (currentBlueprints, blueprint) => {
  return currentBlueprints.filter(
    (currentBlueprint) =>
      currentBlueprint.transferName === blueprint.transferName ||
      currentBlueprint.storageName === blueprint.storageName
  ).length > 0
    ? false
    : true;
};

const getCategorizedBlueprintByTransferName = (blueprint) => {
  const { transferName } = blueprint;
  const type = BLUEPRINT_TYPES.filter((currentType) => {
    const comparableName = currentType.name.replace(/ /g, "_");
    return transferName.includes(comparableName);
  });

  let category = null;

  switch (type.length) {
    case 1:
      category = type[0].category;
      break;
    default:
      category = BLUEPRINT_TYPE_CATEGORIES.SENSOR;
      break;
  }

  return {
    id: blueprint.id,
    category: category,
    blueprint: blueprint,
  };
};

/** @returns {[[],[]]} The blueprints are divided into Sensors And Actions */
const formatBlueprintsByType = (blueprints) => {
  const sensors = [],
    actions = [];

  blueprints.forEach((blueprint) => {
    const categorizedBlueprint = getCategorizedBlueprintByTransferName(
      blueprint
    );
    switch (categorizedBlueprint.category) {
      case BLUEPRINT_TYPE_CATEGORIES.SENSOR:
        sensors.push(categorizedBlueprint);
        break;
      case BLUEPRINT_TYPE_CATEGORIES.ACTION:
        actions.push(categorizedBlueprint);
        break;
      default:
        console.log("UNKNOWN Blueprint category!");
        break;
    }
  });
  return [sensors, actions];
};

export {
  validAgainstDuplicateBlueprint,
  validAgainstEmptyFields,
  formatBlueprintsByType,
};
