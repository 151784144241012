import React, { Component } from "react";
import LoginPanel from "../components/Auth/LoginPanel";
import {
  setRootElementClass,
  ROOT_CLASSNAMES,
} from "../components/utils/root-element-utils";

class Login extends Component {
  state = {};

  constructor() {
    super();
    setRootElementClass(ROOT_CLASSNAMES.auth);
  }
  render() {
    return (
      <div
        className="container-fluid"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <LoginPanel />
      </div>
    );
  }
}

export default Login;
