import {
  USER_LOGIN,
  USER_LOGOUT,
  UPDATE_USERNAME,
  UPDATE_USER_TOKEN,
  UPDATE_PUNISHMENT
} from "./types.js";

export const userLogin = () => {
  return {
    type: USER_LOGIN,
    payload: true
  };
};

export const userLogout = () => {
  return {
    type: USER_LOGOUT,
    payload: false
  };
};

export const updateUsername = username => {
  return {
    type: UPDATE_USERNAME,
    payload: username
  };
};

export const updateUserToken = token => {
  return {
    type: UPDATE_USER_TOKEN,
    payload: token
  };
};

export const updatePunishment = punishmentTime => {
  return {
    type: UPDATE_PUNISHMENT,
    payload: punishmentTime
  };
};
