import React, { Component } from "react";
import "../../../styles/scss/Tasks/add-task.scss";
import SectionTitle from "../../Common/SectionTitle";
import TriggersContainer from "./Triggers/TriggersContainer";
import SubsectionTitle from "./SubsectionTitle";
import Select from "react-dropdown-select";
import IconsGridWrapper from "../../Scheme/IconsGridWrapper";
import { getSchemeRequest } from "../../../api/schemeapi";
import SensorsContext from "../../../contexts/sensors-context";
import interpret, { INTERPRETATION_MODES } from "../Interpreter";
import { v4 as uuid } from "uuid";
import { formatBlueprintsByType } from "../../utils/blueprint-utils";

class TaskControlPanel extends Component {
  triggersContainerRef = React.createRef();
  state = {
    mode: this.props.mode,
    id: uuid(),
    title: "",
    icon: "",
    blueprints: {
      sensors: [],
      actions: [],
    },
    interpretableData: {
      triggers: [],
      actions: [],
    },
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    if (params.taskID) {
      this.compileTaskData(params);
    }

    getSchemeRequest()
      .then((res) => res.payload.scheme)
      .then(({ blueprints }) => {
        const [sensors, actions] = formatBlueprintsByType(blueprints);
        this.setState({
          blueprints: { sensors: sensors, actions: actions },
        });
      });
  }

  compileTaskData = ({ taskID }) => {
    const { tasks, history } = this.props;
    const {
      triggersContainerRef: { current: containerRef },
    } = this;

    if (tasks.length > 0) {
      const targetTask = tasks.find((task) => task.id === taskID);
      if (targetTask) {
        const { id, title, icon, triggers, actions } = targetTask;
        this.setState({
          id,
          title,
          icon,
          interpretableData: { actions, triggers },
        });
        containerRef.initTriggers(triggers);
      } else {
        history.push("/ucp/tasks");
      }
    }
  };

  onSelectedIconChangeHandler = (icon) => {
    this.setState({ icon: icon });
  };

  onTriggersCompiledHandler = (triggers) => {
    this.setState((prev) => ({
      interpretableData: { ...prev.interpretableData, triggers: triggers },
    }));
  };

  onActionsChangeHandler = (actions) => {
    this.setState((prev) => ({
      interpretableData: { ...prev.interpretableData, actions: actions },
    }));
  };

  onTaskSubmit = () => {
    const {
      id,
      title,
      icon,
      interpretableData: { triggers, actions },
    } = this.state;
    const { history, onTaskSubmit } = this.props;

    const task = {
      id,
      title,
      icon,
      triggers,
      actions,
      interpretation: interpret(
        triggers,
        actions,
        INTERPRETATION_MODES.DIVIDED_ARRAY_INTERPRET
      ),
    };

    if (isTaskValid(task)) {
      onTaskSubmit(task);
      history.push("/ucp/tasks");
    } else {
      alert("Some task fields are missing!");
    }
  };

  render() {
    const {
      blueprints: { sensors, actions },
      interpretableData: { triggers, actions: triggeredActions },
      title,
    } = this.state;

    return (
      <div id="add-task-container">
        <SectionTitle title={"Add Task"} />

        <SubsectionTitle title={"Task Title"} />
        <input
          required
          className="form-control"
          style={{ marginBottom: "10px" }}
          placeholder="Water Regulation Control"
          value={title}
          onChange={(e) => this.setState({ title: e.target.value })}
        />

        <SensorsContext.Provider value={sensors}>
          <TriggersContainer
            ref={this.triggersContainerRef}
            onTriggersCompiled={this.onTriggersCompiledHandler}
          />
        </SensorsContext.Provider>

        <SubsectionTitle title={"Actions"} />
        <div id="actions-container" className="dropdown-select-container">
          <Select
            multi
            required
            options={actions}
            values={triggeredActions}
            labelField={"blueprint.storageName"} //Label used in select is this field
            valueField={"id"} //Option key prop is this field
            className="actions-select-input"
            placeholder={"Select actions to trigger"}
            noDataLabel={"No actions created yet!"}
            onChange={(values) => this.onActionsChangeHandler(values)}
          ></Select>
        </div>

        <div id="interpreter-container">
          <span id="interpretation">
            {interpret(triggers, triggeredActions)}
          </span>
        </div>
        <IconsGridWrapper
          onSelectedIconChange={this.onSelectedIconChangeHandler}
        />
        <button
          id="create-task"
          className="btn btn-info"
          onClick={this.onTaskSubmit}
        >
          {submitButtonText(this.props)}
        </button>
      </div>
    );
  }
}

const isTaskValid = (task) => {
  return (
    task.id !== undefined &&
    task.title &&
    task.icon &&
    task.triggers.length > 0 &&
    task.actions.length > 0
  );
};

const submitButtonText = ({ mode }) => {
  switch (mode) {
    case TASK_CP_MODE.ADD:
      return "Create Task";
    case TASK_CP_MODE.EDIT:
      return "Update Task";
    default:
      return "";
  }
};

const TASK_CP_MODE = {
  ADD: "ADD",
  EDIT: "EDIT",
};

export { TaskControlPanel as default, TASK_CP_MODE };
