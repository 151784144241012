import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userLogout } from "../../actions/userActions";

class Logout extends Component {
  componentDidMount() {
    if (this.props.loggedIn) this.props.logout();
  }

  render() {
    return <Redirect to="/" />;
  }
}

const mapStateToProps = state => {
  return { loggedIn: state.user.active };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(userLogout());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
