import React, { Component } from "react";
// import MediaItem from "./MediaItem";
class MediaList extends Component {
  state = {};

  renderMediaItems = () => {
    return <tr />;
  };

  render() {
    return this.props.data === undefined ? <tr /> : this.renderMediaItems;
  }
}

export default MediaList;
