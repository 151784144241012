const INPUT_FORMAT_REGEX = {
  ipAddress:
    "\b(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]).){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))\b",
};

const INPUT_LENGTHS = { ipAddress: 15 };
const INPUT_TYPES = {
  NUMBER: "NUM",
  TEXT: "TXT",
  CHECHKBOX: "CHK",
  CUSTOM: "CSTM",
};

export { INPUT_TYPES, INPUT_FORMAT_REGEX, INPUT_LENGTHS };
