import React, { Component } from "react";
import Header from "../components/Home/HomeHeader";
import Body from "../components/Home/HomeBody";
import Footer from "../components/Home/HomeFooter";
import { connect } from "react-redux";
import axios from "axios";
import PageCategoryBar from "../components/Home/PageCategorySelection/PageCategoryBar";
import { PAGE_CATEGORIES } from "../components/Home/PageCategorySelection/page-categories";
import {
  setRootElementClass,
  ROOT_CLASSNAMES,
} from "../components/utils/root-element-utils";
import "../styles/scss/Home/home.scss";

class Home extends Component {
  state = { selectedPageCategory: PAGE_CATEGORIES.articles };

  constructor() {
    super();
    setRootElementClass(ROOT_CLASSNAMES.home);
  }

  componentDidMount() {
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    if (this.props.authToken.length > 10) {
      axios.defaults.headers.common["X-Auth"] = this.props.authToken;
    }
  }

  onPageCategoryChangeHandler = (pageCategory) => {
    this.setState({ selectedPageCategory: pageCategory });
  };

  render() {
    const { authenticated } = this.props;
    const { selectedPageCategory } = this.state;
    return (
      <div className="container-fluid">
        <Header />
        {authenticated && (
          <PageCategoryBar
            onPageCategoryChange={this.onPageCategoryChangeHandler}
          />
        )}
        <Body pageCategory={selectedPageCategory} />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.user.token,
    authenticated: state.user.active,
  };
};

export default connect(mapStateToProps)(Home);
