import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer className="row">
        <div className="col-md-9 col-lg-10">
          Copyright&copy; <i>Earth Station Modular</i>
        </div>
        <div id="social-media-icons-set" className="col-md-3 col-lg-2">
          <a href="##">
            <i
              className="fab fa-facebook fa-2x facebook social-media-icon"
              aria-hidden="true"
            />
          </a>
          <a href="##">
            <i
              className="fab fa-twitter-square fa-2x social-media-icon"
              aria-hidden="true"
            />
          </a>
          <a href="##">
            <i
              className="fab fa-instagram fa-2x social-media-icon"
              aria-hidden="true"
            />
          </a>
          <a href="##">
            <i
              className="fab fa-linkedin fa-2x social-media-icon"
              aria-hidden="true"
            />
          </a>
        </div>
      </footer>
    );
  }
}

export default Footer;
