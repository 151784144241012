export const groupRecordsByBlueprintsInArray = data => {
  const template = getBlueprintsRecordsTemplate(data);
  const blueprintsIDsArray = template.blueprintsIDsTemplate;
  const dataArray = template.dataTemplate;
  const labelsArray = template.labelsTemplate;
  data.data.forEach(record => {
    dataArray[blueprintsIDsArray.indexOf(record.blueprintID)].push(record);
  });
  return {
    blueprints: blueprintsIDsArray,
    labels: labelsArray,
    data: dataArray
  };
};

// export const groupBlueprintsIDAndLabels = data => {
//   const template = getBlueprintsRecordsTemplate(data);
//   const blueprintsIDsArray = template.blueprintsIDsTemplate;
//   const labelsArray = template.labelsTemplate;
//   return {
//     blueprints: blueprintsIDsArray,
//     labels: labelsArray
//   };
// };

const getBlueprintsRecordsTemplate = data => {
  const template = {
    labelsTemplate: [],
    blueprintsIDsTemplate: [],
    dataTemplate: []
  };
  for (var blueprint in data.blueprints) {
    template.blueprintsIDsTemplate.push(blueprint);
    template.labelsTemplate.push(data.blueprints[blueprint].name);
    template.dataTemplate.push([]);
  }
  return template;
};
