import React, { Component } from "react";
import { Link } from "react-router-dom";

class Button extends Component {
  state = {};
  icon = "fa " + this.props.icon;
  customCoverCardStyle = this.props.coverStyle.coverCard;
  customCoverTitleStyle = this.props.coverStyle.coverCardTitle;
  coverCardStyle =
    this.customCoverCardStyle !== undefined
      ? "cover-card " + this.customCoverCardStyle
      : "cover-card";
  coverCardTitleStyle =
    this.customCoverTitleStyle !== undefined
      ? "cover-card-title " + this.customCoverTitleStyle
      : "cover-card-title";
  render() {
    return (
      <div className="cover-container">
        <div className={this.coverCardStyle}>
          <div className={this.coverCardTitleStyle}>
            <Link to={this.props.route}>
              <i
                className={this.icon}
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
              {this.props.children}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Button;
