import React from "react";
import BlueprintsList from "./BlueprintsList";

export const BlueprintsTable = ({ blueprints, onDelete }) => {
  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Transfer Name</th>
            <th>Display Name</th>
            <th>Mandatory</th>
            <th>Frequency</th>
            <th>Icon</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <BlueprintsList blueprints={blueprints} onDelete={onDelete} />
        </tbody>
      </table>
    </div>
  );
};
