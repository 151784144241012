/* eslint-disable */
import React, { Component } from "react";
import PublicPages from "../datasources/public-pages";
import { Route } from "react-router-dom";
import MusicBody from "../components/Music/MusicBody.jsx";
import Dashboard from "../components/Common/Dashboard";
import ArticleContent from "../components/Article/ArticleBody";
import { clearRootElementClasses } from "../components/utils/root-element-utils";

class Article extends Component {
  constructor() {
    super();
    clearRootElementClasses();
  }

  sideLinks = PublicPages.pages;
  render() {
    return (
      <Dashboard sidelinks={this.sideLinks}>
        {PublicPages.pages.map((element) => {
          if (element.body.valid) {
            return (
              <Route
                key={element.header.index}
                path={element.header.route}
                exact
                render={(routeProps) => <ArticleContent article={element} />}
              />
            );
          }
        })}
        <Route path="/music" component={MusicBody} />
      </Dashboard>
    );
  }
}

export default Article;
