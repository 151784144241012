import React, { Component } from "react";
import { getLatestRecordsRequest } from "../../api/recordapi";
import { recordToRecordDTO } from "../utils/record-utils";
import DataBarIcon from "./DataBarIcon";
import { MESSAGE_SUCCESS } from "../../api/api-info";
import { SHA256 } from "crypto-js";

class DataBar extends Component {
  state = { blueprints: [], data: [] };

  MAXIMUM_DATABAR_ICONS_COUNT = 10;
  periodicRecordsUpdate;

  componentDidMount() {
    this.getRecords();
    this.periodicRecordsUpdate = setInterval(() => {
      this.getRecords();
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.periodicRecordsUpdate);
  }

  getRecords = () => {
    const latestRecordRequest = getLatestRecordsRequest();
    latestRecordRequest.then(res => {
      if (res.status === MESSAGE_SUCCESS) {
        this.setState({
          blueprints: res.payload.blueprints,
          data: res.payload.data
        });
      }
    });
  };

  renderIcons = () => {
    const blueprints = this.state.blueprints;
    const records = this.state.data;
    let databarIconCount = 0;
    return records.length > 0
      ? records.map(record => {
          const recordDTO = recordToRecordDTO(
            record,
            blueprints[record.blueprintID]
          );
          databarIconCount++;
          return databarIconCount < this.MAXIMUM_DATABAR_ICONS_COUNT ? (
            <DataBarIcon
              key={SHA256(
                record.blueprintID + record.value + record.date + record.time
              )}
              record={recordDTO}
            />
          ) : (
            ""
          );
        })
      : "";
  };

  render() {
    return <ul className="status-bar data-bar-list">{this.renderIcons()}</ul>;
  }
}

export default DataBar;
