import React from "react";

const SectionTitle = ({ title }) => {
  return (
    <span id="section-title">
      <h2>{title}</h2>
      <hr />
    </span>
  );
};

export default SectionTitle;
