const PublicPages = {
  pages: [
    {
      header: {
        enabled: true,
        index: "P1",
        title: "Off-Grid Residential",
        icon: "fa-map",
        route: "/off-grid"
      },
      body: {
        valid: true,
        video: { poster: "poster 2.png", playback: "video_render.mp4" },
        article: {
          title: "Off Grid Residential",
          body:
            "ESM Off grid residential modules.\nAutonomous off-grid electricity, water captation + filtration and sewage treatment onboard.\n4000L of filtered water stored under floor.\nModularly expandable in 5 directions.\nDeployable by semi trailer, boat, helicopter and airship.\nBase setup is 640 sq feet, in 4 shipping containers locked together.\nHurricane, tornado, impact and earthquake resistant.\nDeployable within 1 day.\nETH 15,000 150,000 CAD 125,000 USD 110,000 EUR\nThe basic 4 modules can be bought in Ether, CAD, USD or EUR.\n"
        },
        button: {
          title: "Book Now",
          icon: "fas fa-file-contract",
          link: "##",
          "custom-background-color": ""
        },
        map: { longitude: -75.6, latitude: 45.4 }
      }
    },
    {
      header: {
        enabled: true,
        index: "P2",
        title: "Reserve Your Stay",
        icon: "fa-ticket-alt",
        route: "/reserve"
      },
      body: {
        valid: true,
        video: {
          poster: "video_poster2.png",
          playback: "video_render.mp4"
        },
        article: {
          title: "Reserve your stay",
          body:
            "Soon you will be able to reserve your stay at existing as well as future ESM residentially deployed modules.\nOur first unit will be showcased and will be available for reservation in Ottawa-Gatineau, Canada.\nSubsequent units will be deployed in:\n- Tonala, Chiapas, Mexico\n- Playa Potrero, Guanacaste, Costa Rica\n- Santa Marta, Colombia\n- Montreal, Quebec, Canada\n- Toronto, Ontario, Canada\n- Vancouver, British Columbia, Canada\nContact Us to inquire."
        },
        button: {
          title: "Book Now",
          icon: "fas fa-file-contract",
          link: "##",
          "custom-background-color": ""
        },
        map: { longitude: -75.6, latitude: 45.4 }
      }
    },
    {
      header: {
        enabled: true,
        index: "P3",
        title: "EV Charging",
        icon: "fa-plug",
        route: "/evcharge"
      },
      body: {
        valid: true,
        video: { poster: "evcharging.png", playback: "video_render.mp4" },
        article: {
          title: "Electric vehicle charging",
          body:
            "ESM functions as a completely offgrid EV charging station.\nLevel 2 charging at 240 volts AC gives 6 kW at a time.\nThis adds approximately 30 to 35 kms of range to your EV per hour."
        },
        button: {
          title: "Book Now",
          icon: "fas fa-file-contract",
          link: "##",
          "custom-background-color": ""
        },
        map: { longitude: -75.6, latitude: 45.4 }
      }
    },
    {
      header: {
        enabled: true,
        index: "P4",
        title: "Greenhouse",
        icon: "fa-leaf",
        route: "/greenhouse"
      },
      body: {
        valid: true,
        video: { poster: "panels.png", playback: "video_render.mp4" },
        article: {
          title: "Greenhouse add-on for food autonomy",
          body:
            "The top patio of the ESM 4-module base setup easily transforms into a greenhouse.\nWater and electricity are already available for an inifinite array of hydroponic or soil-based greenhouse farming uses.\nThe greenhouse functions in tandem with the main floor residential/restaurant space, stabilizing temperature through water-based thermal storage and exchange.\nContact Us to inquire."
        },
        button: {
          title: "Book Now",
          icon: "fas fa-file-contract",
          link: "##",
          "custom-background-color": ""
        },
        map: { longitude: -75.6, latitude: 45.4 }
      }
    },
    {
      header: {
        enabled: true,
        index: "P5",
        title: "Cafè",
        icon: "fa-street-view",
        route: "/cafe"
      },
      body: {
        valid: true,
        video: { poster: "video_poster.png", playback: "video_render.mp4" },
        article: {
          title: "Earth Station Cafè",
          body:
            "Earth Station Cafe hot-air roasts fair trade, organic coffees from the finest, high altitude and biodiverse coffee plantations in the world. We roast offgrid from solar and wind power stored in saltwater batteries.\nTogether with our coffee farmer friends and colleagues living in the tropics at high altitudes we offer:\n- Coatepec, Veracruz biodiverse coffee\n- Chiapas, Mexico, high altitude\n- Costa Rica Tarrazu\n- Guatemala, Solola, Atitlan high altitude and volcanic.\nContact Us to inquire."
        },
        button: {
          title: "Book Now",
          icon: "fas fa-file-contract",
          link: "##",
          "custom-background-color": ""
        },
        map: { longitude: -75.6, latitude: 45.4 }
      }
    },
    {
      header: {
        enabled: true,
        index: "P6",
        title: "Commercial/Industrial",
        icon: "fa-money-check-alt",
        route: "/commercial"
      },
      body: {
        valid: true,
        video: { poster: "industrial.png", playback: "video_render.mp4" },
        article: {
          title: "ESM for commercial and light industrial usage",
          body:
            "ESM modules can easily be used for offgrid commercial and light industrial uses with almost no limits.\nAn infinite number of businesses can be operated offgrid, with no environmental footprint or waste.\nIn order to showcase the possibilities, we will operate an offgrid cafe/restaurant, artistic venue and EV charging station in Ottawa-Gatineau, Canada in 2018.\nContact Us for further details."
        },
        button: {
          title: "Book Now",
          icon: "fas fa-file-contract",
          link: "##",
          "custom-background-color": ""
        },
        map: { longitude: -75.6, latitude: 45.4 }
      }
    },
    {
      header: {
        enabled: true,
        index: "P7",
        title: "Music & Culture",
        icon: "fa-music",
        route: "/music"
      },
      body: { valid: false }
    }
  ]
};

export default PublicPages;
