import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { MapConfig } from "../configs/google-maps-config";

export const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultOptions={MapConfig}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
    />
  ))
);

export const DefaultContainerElementConfig = (
  <div style={{ height: "220px" }} />
);
export const DefaultMapElementConfig = <div style={{ height: "100%" }} />;
export const DefaultLoadingElementConfig = <div style={{ height: "100%" }} />;
export const DefaultGoogleMapURL =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyCRjWHR2gKVUrnNrKWkKAArOOcv0K8WvPk";

export const CustomContainerElementConfig = customConfig => {
  for (let [key, value] of Object.entries(customConfig)) {
    if (value === undefined) {
      delete customConfig[key];
    }
  }
  if (customConfig.length < 1) {
    return <div style={{ height: "220px" }} />;
  }
  return <div style={customConfig} />;
};
