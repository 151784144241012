import React, { Component } from "react";

class PageCategory extends Component {
  onClickCategoryHandler = () => {
    const { onSelectCategory, categoryFlag } = this.props;
    onSelectCategory(categoryFlag);
  };

  render() {
    const {
      categoryName,
      categoryFlag,
      currentlySelectedCategory
    } = this.props;

    return (
      <div
        className={`page-category ${
          currentlySelectedCategory === categoryFlag ? "active" : ""
        }`}
        onClick={this.onClickCategoryHandler}
      >
        {categoryName}
      </div>
    );
  }
}

export default PageCategory;
