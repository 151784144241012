import React, { Component } from "react";
import PublicPages from "../../datasources/public-pages";
import UserPages from "../../datasources/ucp-pages";
import CoverButton from "./NavigationButton";
import { connect } from "react-redux";
import { PAGE_CATEGORIES } from "./PageCategorySelection/page-categories";

/* Adds custom style to the Home Screen cover buttons according to if it is UCP/Public/ACP */
const UCP_STYLES = {
  coverCard: "ucp-cover-card",
  coverCardTitle: "ucp-cover-card-title",
};

const CUSTOM_COVER_STYLE = { PUBLIC: {}, UCP: UCP_STYLES };

class Body extends Component {
  render() {
    const { loggedIn: authenticated, pageCategory } = this.props;

    return (
      <div className="vertical-center row home-content-pane">
        {pageCategory === PAGE_CATEGORIES.articles &&
          PublicPages.pages.map((page) => {
            return (
              <CoverButton
                key={page.header.index}
                route={page.header.route}
                icon={page.header.icon}
                coverStyle={CUSTOM_COVER_STYLE.PUBLIC}
              >
                {page.header.title}
              </CoverButton>
            );
          })}

        {authenticated &&
          pageCategory === PAGE_CATEGORIES.userControlPanel &&
          UserPages.pages.map((page) => {
            return page.header.enabled ? (
              <CoverButton
                key={page.header.index}
                route={page.header.route}
                icon={page.header.icon}
                coverStyle={CUSTOM_COVER_STYLE.UCP}
              >
                {page.header.title}
              </CoverButton>
            ) : (
              ""
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { loggedIn: state.user.active };
};

export default connect(mapStateToProps)(Body);
