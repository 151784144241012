import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";

class Body extends Component {
  state = {};

  render() {
    return (
      <div id="page-content" className="col-lg-10 display-table-cell">
        {!this.props.headerless && <Header />}
        <div id="content-container" className="dashboard-content-pane">
          {this.props.children}
        </div>
        {!this.props.footerless && <Footer />}
      </div>
    );
  }
}

export default Body;
