import React from "react";
import { useHistory } from "react-router-dom";

const TaskCard = ({
  id,
  title,
  icon = "fas fa-code",
  conditions,
  actions,
  onDelete,
}) => {
  const history = useHistory();
  const editForwardURL = `/ucp/tasks/tcp/${id}`;

  return (
    <div className="task-card-container">
      <div className="task-card">
        <h3 className="title">
          <i className={icon}></i>
          {title}
        </h3>
        <h4>Condition</h4>
        <div className="condition-container">{conditions}</div>
        <h4>Action</h4>
        <div className="action-container">{actions}</div>
        <div className="buttons-container">
          <i
            className="far fa-edit"
            onClick={() => history.push(editForwardURL)}
          ></i>
          <i className="far fa-trash-alt" onClick={() => onDelete(id)}></i>
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
