import { MEMORY_BLOCK_TYPES_STANDARD } from "./MemoryBlockType";
import { logError, logWarning } from "../logger/Logger";
import Observable from "../observers/Observable";
import LocalStorageManager from "../localstorage/LocalStorageManager";
import { mapToArray } from "../serializer/SerializeOps";
import { arrayToMemoryMap } from "../serializer/DeserializeOps";
const CACHER_LOCALSTORAGE_TITLE = "CACHER";

class Cacher extends Observable {
  constructor() {
    const LocalStorageObserver = new LocalStorageManager();
    super(LocalStorageObserver);
    const deserializeOps = [arrayToMemoryMap];
    const savedMemoryMap = LocalStorageObserver.getFromLocalStorage(
      CACHER_LOCALSTORAGE_TITLE,
      deserializeOps
    );
    this.memoryMap = new Map();
    if (savedMemoryMap !== undefined) {
      this.memoryMap = savedMemoryMap;
    } else {
      this.initMemoryMap();
      const serializeOps = [mapToArray];
      this.notify(CACHER_LOCALSTORAGE_TITLE, this.memoryMap, serializeOps);
    }
  }

  initMemoryMap() {
    const tempMemoryMap = new Map();
    MEMORY_BLOCK_TYPES_STANDARD.forEach(blockType => {
      tempMemoryMap.set(blockType, []);
    });
    this.memoryMap = tempMemoryMap;
  }

  updateMemoryBlock(memoryBlock) {
    /*
      ::MemoryBlockType::
      
      type:{
        name: "MEMORY_BLOCK_TYPE_NAME",
        maxBlocks: X 
      } 
    */
    const { memoryBlockType: type, payload } = memoryBlock.getMemoryBlockObj();
    /*
    ::MemoryMapNode::

    [
            MemoryBlock1.payload,
            MemoryBlock2.payload,
            ..
        ]
        */
    const memoryMapNode = this.memoryMap.get(type);
    if (memoryMapNode === undefined) {
      logError(this, "Invalid memory block type");
      return;
    }

    while (memoryMapNode.length >= type.maxBlocks) {
      memoryMapNode.shift();
    }
    /* Returning to avoid duplicates */
    if (memoryMapNode.indexOf(payload) !== -1) {
      return;
    }
    memoryMapNode.push(payload);
    this.memoryMap.set(type, memoryMapNode);
    this.notify(CACHER_LOCALSTORAGE_TITLE, this.memoryMap, [mapToArray]);
  }

  getMemoryBlock(memoryType) {
    const memoryMapNode = this.memoryMap.get(memoryType);
    if (memoryMapNode === undefined) {
      logWarning(this, "Empty memory map node");
    } else {
      return memoryMapNode;
    }
  }

  setMemoryBlockByIndex(memoryBlock, index) {
    const { memoryBlockType: type, payload } = memoryBlock.getMemoryBlockObj();
    const memoryMapNode = this.memoryMap.get(type);

    if (index >= memoryMapNode.length) {
      logError(this, "Invalid memory block index!");
      return;
    }

    while (
      memoryMapNode.length < index + 1 &&
      memoryMapNode.length < type.maxBlocks
    ) {
      memoryMapNode.push("");
    }
    memoryMapNode[index] = payload;
    this.memoryMap.set(type, memoryMapNode);
    this.notify(CACHER_LOCALSTORAGE_TITLE, this.memoryMap, [mapToArray]);
  }
}

export default Cacher;
