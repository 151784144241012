import React, { Component } from "react";
import { BLUEPRINT_TYPES, BLUEPRINT_TYPE_CATEGORIES } from "./BlueprintTypes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const renderTypeCategory = ({ category }, index) => {
  const style = { marginBottom: "2px" };
  return (
    <Tooltip
      className={`tooltip-${category.toLowerCase()}`}
      style={style}
      id={`tooltip-${index}`}
    >
      {category}
    </Tooltip>
  );
};

class BlueprintTypes extends Component {
  state = { currentlySelectedType: BLUEPRINT_TYPES[0].name };

  updateSelectedType = (type) => {
    this.setState({ currentlySelectedType: type });
  };

  render() {
    const { currentlySelectedType } = this.state;
    const { onSelectedTypeChanged } = this.props;

    return BLUEPRINT_TYPES.map((type, index) => {
      const blueprintTypeName = type.name;
      const isAction = type.category === BLUEPRINT_TYPE_CATEGORIES.ACTION;
      const isButtonActive = currentlySelectedType === blueprintTypeName;
      const activeButtonClass = `btn ${
        isButtonActive
          ? isAction
            ? "btn-warning btn-warning-light"
            : "btn-success btn-success-light"
          : "btn-dark"
      }`;

      return (
        <OverlayTrigger
          key={blueprintTypeName + index}
          placement="top"
          delay={{ show: 250, hide: 280 }}
          overlay={renderTypeCategory(type, index)}
        >
          <button
            key={blueprintTypeName + index}
            className={activeButtonClass}
            value={blueprintTypeName}
            onClick={() => {
              this.updateSelectedType(blueprintTypeName);
              onSelectedTypeChanged(type);
            }}
          >
            {blueprintTypeName}
          </button>
        </OverlayTrigger>
      );
    });
  }
}

export default BlueprintTypes;
