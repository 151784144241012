import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { isMobile } from "react-device-detect";
import {
  DoubleAxisChartOptionsWithoutLegends,
  DoubleAxisChartOptionsWithLegends,
} from "../../configs/chart-config";
import DateTimePicker from "../DateTimePickerWrapper";
import VariableSelectionPanel from "../VariableSelectionPanel";
import { connect } from "react-redux";
import { generateChartData } from "../../utils/chart-dataset-generator";
import { getSchemeRequest } from "../../../api/schemeapi";
import { getDataByTimeAndDateRequest } from "../../../api/recordapi";
import { mapToObject } from "../../utils/map-utils";
import { setLoading } from "../../../actions/utilsActions";
import { groupRecordsByBlueprintsInArray } from "../../utils/data-structure-utils";
import { MESSAGE_SUCCESS } from "../../../api/api-info";
import { OPEN_DOWN } from "react-dates/lib/constants";
import moment from "moment";
import AttachedButtonsGroup, {
  AttachedButtonNode,
} from "./AttachedButtonsGroup.jsx";
import "../../../styles/scss/Common/custom-input.scss";

class ChartPanel extends Component {
  state = {
    data: {},
    missingDateOrTime: false,
    blueprints: [],
    selectedVariables: [],
  };
  dateAndTimeMap = new Map();

  componentDidMount() {
    this.getScheme();
    this.initGraph();
  }

  initGraph = () => {
    const startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    const startTime = moment().format("HH:mm");
    const endTime = moment().format("HH:mm");

    this.graphDataAndSetupTiming(startDate, endDate, startTime, endTime);
  };

  setupDateAndTimeMap = (startDate, endDate, startTime, endTime) => {
    this.updateDateAndTimeMap("startDate", startDate);
    this.updateDateAndTimeMap("endDate", endDate);
    this.updateDateAndTimeMap("startTime", startTime);
    this.updateDateAndTimeMap("endTime", endTime);
  };

  graphData = () => {
    this.props.loadingState(true);
    const dateAndTimeObj = mapToObject(this.dateAndTimeMap);
    if (this.emptyDateOrTime(this.dateAndTimeMap)) {
      this.setState({ missingDateOrTime: true });
    } else {
      const data = getDataByTimeAndDateRequest(dateAndTimeObj);
      this.setState({ missingDateOrTime: false });
      data.then((res) => {
        if (res !== undefined) {
          if (res.status === MESSAGE_SUCCESS) {
            const rawData = res.payload;
            const chartData = this.createChartData(rawData);
            this.setState({
              data: chartData,
            });
          }
        }
      });
      this.props.loadingState(false);
    }
  };

  graphDataAndSetupTiming = (startDate, endDate, startTime, endTime) => {
    this.setupDateAndTimeMap(startDate, endDate, startTime, endTime);
    this.graphData();
  };

  createChartData = (rawData) => {
    const labelsAndBlueprints = groupRecordsByBlueprintsInArray(rawData);
    const selectedVariables = this.state.selectedVariables;
    const datasets = generateChartData(
      labelsAndBlueprints.labels,
      rawData.data,
      labelsAndBlueprints.data,
      labelsAndBlueprints.blueprints,
      selectedVariables
    );
    return datasets;
  };

  onVariableSelectionCheck = (selectedVariables) => {
    this.setState({ selectedVariables: [...selectedVariables] });
  };

  getScheme = () => {
    this.props.loadingState(true);
    const schemeRequest = getSchemeRequest();
    schemeRequest
      .then((response) => {
        if (response.payload !== undefined) {
          this.setState({ blueprints: response.payload.scheme.blueprints });
        }
      })
      .finally((res) => {
        // this.props.loadingState(false);
      });
  };

  updateDateAndTimeMap = (key, value) => {
    this.dateAndTimeMap.set(key, value);
  };

  emptyDateOrTime = (map) => {
    if (
      map.get("startDate") === undefined ||
      map.get("startDate") === "" ||
      map.get("endDate") === undefined ||
      map.get("endDate") === "" ||
      map.get("startTime") === undefined ||
      map.get("startTime") === "" ||
      map.get("endTime") === undefined ||
      map.get("endTime") === ""
    ) {
      return true;
    }
    return false;
  };

  displayGraphOnClick = () => {
    this.graphData();
  };

  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="info-card full-opacity-info-card">
            <div className="info-card-icon">
              <i className="fas fa-chart-area fa-2x" aria-hidden="true" />
            </div>
            <div className="info-card-title">Inclusive Charts</div>
            <div
              className="info-card-value"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              {isMobile ? (
                <div className="graphContainer">
                  <Line
                    data={this.state.data}
                    options={DoubleAxisChartOptionsWithoutLegends}
                  />
                </div>
              ) : (
                <Line
                  data={this.state.data}
                  options={DoubleAxisChartOptionsWithLegends}
                />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexGrow: 1,
                  flexWrap: "wrap",
                  marginTop: "12px",
                }}
              >
                <VariableSelectionPanel
                  target={"graph"}
                  blueprints={this.state.blueprints}
                  onCheck={this.onVariableSelectionCheck}
                />
                <DateTimePicker
                  target={"graph"}
                  dateOrTimeMissing={this.state.missingDateOrTime}
                  updateDateAndTime={this.updateDateAndTimeMap}
                  openDirection={OPEN_DOWN}
                />
                {/* <button
                  id="graph-display-button"
                  className="btn btn-primary"
                  style={{ marginBottom: "10px", flexBasis: "97%" }}
                  type="button"
                  onClick={this.displayGraphOnClick}
                >
                  Display Graph
                </button> */}
                <AttachedButtonsGroup
                  leftButton={
                    new AttachedButtonNode(
                      "Display Graph",
                      this.displayGraphOnClick,
                      "fa-calendar-alt"
                    )
                  }
                  midButtons={this.midAttachedButtonNode}
                  rightButton={this.rightAttachedButtonNode}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  rightAttachedButtonNode = new AttachedButtonNode(
    "Last 7 Days",
    () => {
      const startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
      const endDate = moment().format("YYYY-MM-DD");
      const startTime = moment().format("HH:mm");
      const endTime = moment().format("HH:mm");
      this.graphDataAndSetupTiming(startDate, endDate, startTime, endTime);
    },
    "fa-calendar-week"
  );

  midAttachedButtonNode = [
    new AttachedButtonNode(
      "Last 24 Hours",
      () => {
        const startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        const startTime = moment().format("HH:mm");
        const endTime = moment().format("HH:mm");
        this.graphDataAndSetupTiming(startDate, endDate, startTime, endTime);
      },
      "fa-calendar-day"
    ),
  ];
}

// const leftAttachedButtonNode = new AttachedButtonNode("Last 24 Hours", () => {
//   console.log("LEFT BUTTON");
// });

const mapStateToProps = (state) => {
  return {
    labels: state.dataset.labels,
    data: state.dataset.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadingState: (loadingState) => {
      dispatch(setLoading(loadingState));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartPanel);
