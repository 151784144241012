export const logError = (source, message) => {
  console.error(
    getSourceName(source),
    " [",
    new Date().toUTCString(),
    "]: ",
    message
  );
};

export const logWarning = (source, ...message) => {
  console.warn(
    getSourceName(source),
    " [",
    new Date().toUTCString(),
    "]: ",
    ...message
  );
};

export const logInfo = (source, ...message) => {
  console.info(
    getSourceName(source),
    " [",
    new Date().toUTCString(),
    "]: ",
    ...message
  );
};

const getSourceName = (source) => {
  if (typeof source === "object") {
    //Object Type
    return source.constructor.name;
  } else if (typeof source === "function") {
    //Class Type
    return source.name;
  } else {
    return source;
  }
};
