import React, { Component } from "react";
import Dashboard from "../components/Common/Dashboard";
import AnalyticsBody from "../components/Analytics/AnalyticsBody";
import SchemeBody from "../components/Scheme/SchemeBody";
import { Route, Switch, Redirect } from "react-router-dom";
import UserPages from "../datasources/ucp-pages";
import MediaManagement from "../components/Media/MediaManagement";
import TasksManagement from "../components/Tasks/TasksManagement";
import { clearRootElementClasses } from "../components/utils/root-element-utils";
import "../styles/scss/Common/dashboard.scss";

class UserControlPanel extends Component {
  constructor() {
    super();
    clearRootElementClasses();
  }

  sidelinks = UserPages.pages;
  render() {
    const basePath = "/ucp";

    return (
      <Dashboard sidelinks={this.sidelinks}>
        <Switch>
          <Route
            path={`${basePath}/analytics`}
            exact
            component={AnalyticsBody}
          />
          <Route path={`${basePath}/scheme`} exact component={SchemeBody} />
          <Route path={`${basePath}/media`} exact component={MediaManagement} />
          <Route path={`${basePath}/tasks`} component={TasksManagement} />
          <Route render={() => <Redirect to="/ucp/analytics" />} />
        </Switch>
      </Dashboard>
    );
  }
}

export default UserControlPanel;
