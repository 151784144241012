import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userLogout } from "../../actions/userActions";
import { extractUsernameFromEmail } from "../utils/string-utils";
import Logo from "../../resources/assets/imgs/logo.png";
import Button from "../Common/button.jsx";

class Header extends Component {
  state = {};
  render() {
    const loginButton = (
      <div className="user-info col-lg-2 col-sm-12">
        <Button to="/login">
          <i
            className="fas fa-lock-open"
            aria-hidden="true"
            style={{ marginRight: "10px" }}
          />
          Login
        </Button>
      </div>
    );

    const logoutButton = (
      <div className="user-info col-lg-2 col-sm-12">
        <div className="username">
          {extractUsernameFromEmail(this.props.username)}
        </div>
        <Button to="/" onClick={this.props.logout}>
          <i
            className="fa fa-lock"
            aria-hidden="true"
            style={{ marginRight: "10px" }}
          />
          Logout
        </Button>
      </div>
    );

    return (
      <header className="row home-header">
        <div id="logo" className="col-lg-4 col-sm-12">
          <Link to="/">
            <img src={Logo} alt="Logo" />
            <span>Earth Station Modular</span>
          </Link>
        </div>
        <div className="col-lg-6" />
        {this.props.loggedIn ? logoutButton : loginButton}
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return { username: state.user.username, loggedIn: state.user.active };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(userLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
