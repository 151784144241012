import {
  SET_START_DATE,
  SET_END_DATE,
  SET_START_TIME,
  SET_END_TIME
} from "./types.js";

export const setStartDate = startDate => {
  return {
    type: SET_START_DATE,
    payload: startDate
  };
};

export const setEndDate = endDate => {
  return {
    type: SET_END_DATE,
    payload: endDate
  };
};

export const setStartTime = startTime => {
  return {
    type: SET_START_TIME,
    payload: startTime
  };
};

export const setEndTime = endTime => {
  return {
    type: SET_END_TIME,
    payload: endTime
  };
};
