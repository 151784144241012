import React, { Component } from "react";
import PageCategory from "./PageCategory";
import { PAGE_CATEGORIES } from "./page-categories.js";

class PageCategoryBar extends Component {
  state = { currentlySelectedCategory: PAGE_CATEGORIES.articles };

  onSelectCategoryHandler = categoryFlag => {
    const { onPageCategoryChange } = this.props;
    onPageCategoryChange(categoryFlag);
    this.setState({ currentlySelectedCategory: categoryFlag });
  };

  render() {
    const { currentlySelectedCategory: selectedCategory } = this.state;
    return (
      <div className="page-category-container">
        <PageCategory
          categoryName="Articles"
          categoryFlag={PAGE_CATEGORIES.articles}
          currentlySelectedCategory={selectedCategory}
          onSelectCategory={this.onSelectCategoryHandler}
        />

        <PageCategory
          categoryName="User Control Panel"
          categoryFlag={PAGE_CATEGORIES.userControlPanel}
          currentlySelectedCategory={selectedCategory}
          onSelectCategory={this.onSelectCategoryHandler}
        />
      </div>
    );
  }
}

export default PageCategoryBar;
