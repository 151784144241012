import React, { Component } from "react";
import Select from "react-dropdown-select";

class DerivedBlueprintsMenu extends Component {
  state = {
    values: [],
    resetFieldFlag: false,
  };

  getBlueprintOptions = () => {
    const { blueprints } = this.props;
    return blueprints.map((blueprint) => ({
      label: blueprint.storageName,
      value: blueprint.storageName,
    }));
  };

  resetField = () => {
    this.setState({ values: [] });
  };

  onChange = (blueprints) => {
    const { index, onFieldUpdate } = this.props;
    const blueprintsFormattedString = blueprints
      .map((blueprints) => blueprints.value)
      .join(",");
    onFieldUpdate(index, blueprintsFormattedString);
    this.setState({ values: blueprints });
  };

  render() {
    const { name, htmlName, placeholder } = this.props;
    return (
      <div key={name} className="form-group col">
        <label htmlFor={htmlName}>{name}</label>
        <Select
          multi
          options={this.getBlueprintOptions()}
          values={this.state.values}
          onChange={(values) => this.onChange(values)}
          placeholder={placeholder}
          className="derived-blueprints-select"
        ></Select>
      </div>
    );
  }
}

export default DerivedBlueprintsMenu;
