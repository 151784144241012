export const MEMORY_BLOCK_TYPE_GENERIC = {
  name: "MEMORY_BLOCK_GENERIC",
  maxBlocks: 10
};

export const MEMORY_BLOCK_TYPE_GRAPH = {
  name: "MEMORY_BLOCK_GRAPH",
  maxBlocks: 2
};

export const MEMORY_BLOCK_TYPE_TABLE = {
  name: "MEMORY_BLOCK_TABLE",
  maxBlocks: 1
};

export const MEMORY_BLOCK_TYPE_DATES = {
  name: "MEMORY_BLOCK_DATES",
  maxBlocks: 2
};

export const MEMORY_BLOCK_TYPE_TIME = {
  name: "MEMORY_BLOCK_TIME",
  maxBlocks: 2
};

export const MEMORY_BLOCK_TYPES_STANDARD = [
  MEMORY_BLOCK_TYPE_GRAPH,
  MEMORY_BLOCK_TYPE_TABLE,
  MEMORY_BLOCK_TYPE_DATES,
  MEMORY_BLOCK_TYPE_TIME
];

export const getMemoryBlockTypeByName = typeName => {
  switch (typeName) {
    case MEMORY_BLOCK_TYPE_GRAPH.name:
      return MEMORY_BLOCK_TYPE_GRAPH;
    case MEMORY_BLOCK_TYPE_TABLE.name:
      return MEMORY_BLOCK_TYPE_TABLE;
    default:
      return MEMORY_BLOCK_TYPE_GENERIC;
  }
};
