import {
  UPDATE_USER_TOKEN,
  USER_LOGIN,
  USER_LOGOUT,
  UPDATE_USERNAME,
  UPDATE_PUNISHMENT,
} from "../actions/types.js";
import axios from "axios";

const initialState = {
  token: "",
  username: "",
  role: "",
  active: false,
  punishment: "-",
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case UPDATE_USER_TOKEN:
      axios.defaults.headers.common["X-Auth"] = payload;
      return { ...state, token: payload };
    case USER_LOGIN:
      return { ...state, active: payload };
    case USER_LOGOUT:
      return { ...state, active: payload, token: "", username: "" };
    case UPDATE_USERNAME:
      return { ...state, username: payload };
    case UPDATE_PUNISHMENT:
      return { ...state, punishment: payload };
    default:
      return state;
  }
}
