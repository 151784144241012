export const recordToRecordDTO = (record, blueprint) => {
  return {
    blueprintID: record.blueprintID,
    date: record.date,
    time: record.time,
    name: blueprint.name,
    value: record.value,
    icon: blueprint.icon
  };
};
