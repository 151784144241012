import React from "react";

const deleteBlueprint = event => {
  event.preventDefault();

  return (blueprint, onDelete) => {
    onDelete(blueprint);
  };
};

const Blueprint = ({ blueprint, onDelete }) => {
  return (
    <tr id={blueprint.id} key={blueprint.id}>
      <th>{blueprint.transferName}</th>
      <th>{blueprint.storageName}</th>
      <td>{blueprint.mandatory ? "Yes" : "No"}</td>
      <td>{blueprint.frequency}</td>
      <td>
        <i className={blueprint.icon} />
      </td>
      <td>
        <button
          className="btn btn-danger"
          onClick={event => deleteBlueprint(event)(blueprint, onDelete)}
        >
          <i className="far fa-trash-alt" />
        </button>
      </td>
    </tr>
  );
};

export default Blueprint;
