import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class ProtectedRoute extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        {this.props.loggedIn ? (
          <Route
            patch={this.props.path}
            render={() => {
              return this.props.children;
            }}
          />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: this.props.location } }}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.user.active,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
