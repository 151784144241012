import { getMemoryBlockTypeByName } from "../cache/MemoryBlockType";
export const arrayToMemoryMap = array => {
  const memoryMap = new Map();
  array.forEach(memoryNode => {
    //  "name" index + 1? Check objectKeysToOneArray serialization operation
    const memoryNodeValue = memoryNode[1];
    const memoryNodeType = getMemoryBlockTypeByName(
      getMemoryNodeTypeName(memoryNode)
    );
    memoryMap.set(memoryNodeType, memoryNodeValue);
  });
  return memoryMap;
};

const getMemoryNodeTypeName = array => {
  let result = undefined;
  array.forEach(subArray => {
    if (subArray !== undefined && subArray !== null) {
      if (subArray.length > 0) {
        subArray.forEach(deepArray => {
          const nameFlagIndex = deepArray.indexOf("name");
          if (nameFlagIndex > -1) {
            result = deepArray[nameFlagIndex + 1];
          }
        });
      }
    }
  });
  return result;
};
