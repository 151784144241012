import React, { Component } from "react";
import Cacher from "../../core/cache/Cacher";
import {
  MEMORY_BLOCK_TYPE_GRAPH,
  MEMORY_BLOCK_TYPE_TABLE,
} from "../../core/cache/MemoryBlockType";
import MemoryBlock from "../../core/cache/MemoryBlock";

const inlineStyling = {
  containerForm: {
    flexBasis: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 5px 0px 5px",
  },
  inputCheckbox: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "-1",
    backgroundColor: "#00000000",
  },
  label: {
    position: "relative",
    zIndex: "2",
    display: "inline",
  },
};

class VariableSelectionPanel extends Component {
  state = { checked: [] };

  componentDidMount() {
    this.cacher = new Cacher();
    this.initSelectedVariables();
  }

  initSelectedVariables() {
    const variableSelectionType = this.props.target; //"graph" or table

    const memoryBlockPayload = this.cacher.getMemoryBlock(
      variableSelectionType === "graph"
        ? MEMORY_BLOCK_TYPE_GRAPH
        : MEMORY_BLOCK_TYPE_TABLE
    );

    if (memoryBlockPayload !== undefined) {
      this.setState({ checked: memoryBlockPayload });
      this.props.onCheck(memoryBlockPayload);
    }
  }

  onCheckChange = (e) => {
    e.persist();
    const eventDispatcher = e.target;
    const checkedArray = [...this.state.checked];

    if (eventDispatcher.checked) {
      checkedArray.push(eventDispatcher.value);
      this.setState({
        checked: checkedArray,
      });

      const memoryBlockType =
        this.props.target === "graph"
          ? MEMORY_BLOCK_TYPE_GRAPH
          : MEMORY_BLOCK_TYPE_TABLE;
      const memoryBlock = new MemoryBlock(
        memoryBlockType,
        eventDispatcher.value
      );
      this.cacher.updateMemoryBlock(memoryBlock);
    } else {
      const indexOfElement = checkedArray.indexOf(eventDispatcher.value);
      if (indexOfElement > -1) {
        checkedArray.splice(indexOfElement, 1);
        this.setState({ checked: checkedArray });
      }
    }
    this.props.onCheck(checkedArray);
  };

  renderBlueprintCheckboxes = () => {
    const activeCheckboxes = this.state.checked;

    return (
      this.props.blueprints.length > 0 &&
      this.props.blueprints.map((blueprint) => {
        const { storageName } = blueprint;
        const checked = activeCheckboxes.indexOf(storageName) !== -1;
        const activeLabelClass = `btn ${
          activeCheckboxes.includes(storageName) ? "btn-info" : "btn-dark"
        }`;

        return (
          <label
            className={activeLabelClass}
            key={storageName}
            style={inlineStyling.label}
          >
            <input
              type="checkbox"
              checked={checked}
              style={inlineStyling.inputCheckbox}
              value={storageName}
              readOnly
            />
            <span>{storageName}</span>
          </label>
        );
      })
    );
  };

  render() {
    return (
      <form
        id="variable-selection"
        className="checkbox-inline"
        style={inlineStyling.containerForm}
        onChange={this.onCheckChange}
      >
        {this.renderBlueprintCheckboxes()}
      </form>
    );
  }
}

export default VariableSelectionPanel;
