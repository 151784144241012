import React, { Component } from "react";
import { Link } from "react-router-dom";

class Button extends Component {
  render() {
    return (
      <Link
        to={this.props.to}
        onClick={() => {
          if (this.props.onClick !== undefined) {
            this.props.onClick();
          }
        }}
      >
        <button
          className="btn btn-outline-info btn-sm"
          style={{
            padding: "3px 20px",
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: "500"
          }}
        >
          {this.props.children}
        </button>
      </Link>
    );
  }
}

export default Button;
