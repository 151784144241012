import React from "react";
import Blueprint from "./Blueprint";

const BlueprintsList = ({ blueprints, onDelete }) => {
  return (
    <>
      {blueprints.length > 0 ? (
        blueprints.map((blueprint) => {
          return (
            <Blueprint
              key={blueprint.transferName}
              blueprint={blueprint}
              onDelete={onDelete}
            />
          );
        })
      ) : (
        <tr>
          <th>No entries found!</th>
        </tr>
      )}
    </>
  );
};

export default BlueprintsList;
