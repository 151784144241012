import React, { Component } from "react";
import SideLink from "./SideLink";
import "../../styles/scss/Common/animations.scss";
import { connect } from "react-redux";
import Logo from "../../resources/assets/imgs/logo.png";
import { withRouter } from "react-router-dom";

const inlineStyling = {
  sidebarLinks: { maxHeight: "90vh", overflow: "auto" },
};

class SideBar extends Component {
  constructor(props) {
    super(props);
    const { history } = props;
    const currentLocation = history.location.pathname;
    history.listen((location, action) => {
      this.setState({
        currentLocation: location.pathname,
      });
    });

    this.state = {
      currentLocation: currentLocation,
    };
  }

  render() {
    const { links, loggedIn } = this.props;

    return (
      <div id="side-bar" className="col-lg-2 display-table-cell">
        <div id="logo">
          <a href="/">
            <img src={Logo} alt="Logo" />
          </a>
        </div>
        <button
          id="collapse-button"
          className="btn"
          data-toggle="collapse"
          data-target="#side-bar-links"
        >
          <i className="fa fa-bars" aria-hidden="true" />
        </button>
        <div
          id="side-bar-links"
          className="collapse"
          style={inlineStyling.sidebarLinks}
        >
          <ul>
            <SideLink enabled={true} route="/" icon="fas fa-home">
              Home
            </SideLink>

            {links.map((page) => {
              const { index, route, icon, title, enabled } = page.header;
              const { currentLocation } = this.state;
              const active = currentLocation.includes(route);
              return (
                <SideLink
                  enabled={enabled}
                  key={index}
                  route={route}
                  icon={icon}
                  active={active}
                >
                  {title}
                </SideLink>
              );
            })}
          </ul>

          <SideLink
            enabled={loggedIn}
            route={"/logout"}
            icon="fas fa-lock status-icon"
          >
            Logout
          </SideLink>

          <SideLink
            enabled={!loggedIn}
            route={"/login"}
            icon="fa fa-sign-in-alt status-icon"
          >
            Login
          </SideLink>
        </div>

        <div className="fixed-sidebar-footer">
          <hr />
          <h4>ESM-Web</h4>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { loggedIn: state.user.active };
};

export default connect(mapStateToProps)(withRouter(SideBar));
