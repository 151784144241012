import React, { Component } from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import MapWrapper from "../Common/MapWrapper";
import "../../styles/scss/Media/media.scss";
import MediaList from "./MediaList";
// import DateTimePicker from "../LiveData/datetimepicker";

class MediaManagement extends Component {
  state = { locatedMedia: true, data: undefined };

  getVideoSizingByColumn = () => {
    return this.state.locatedMedia ? "col-lg-7" : "col-lg-12";
  };

  onMediaItemSelect = () => {
    this.setState({ locatedMedia: !this.state.locatedMedia });
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <span className={this.getVideoSizingByColumn()}>
            <Video
              className="media-player"
              poster="https://cdn.shopify.com/s/files/1/1757/6457/products/mm3_530x@2x.png?v=1519590144"
            >
              <source
                src="https://download.blender.org/durian/trailer/sintel_trailer-720p.mp4"
                type="video/webm"
              />
            </Video>
          </span>
          <div className="col-lg-5 media-map-wrapper">
            {this.state.locatedMedia ? (
              <MapWrapper
                height={"300px"}
                width={"auto"}
                lat={45.4}
                lng={-75.6}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 scrollable-table media-table-wrapper">
            {this.state.data === undefined ? <h1>No data available</h1> : ""}
            <table
              cellSpacing="0"
              className="media-table table table-dark table-striped table-bordered table-sm"
            >
              <thead className="media-table-header">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                  <th scope="col">Type</th>
                  <th scope="col" />
                  <th scope="col" />
                </tr>
              </thead>
              <tbody className="media-table-body">
                <MediaList
                  onMediaItemSelect={this.onMediaItemSelect}
                  data={this.state.data}
                />
              </tbody>
            </table>
            {/* <DateTimePicker
              target="media-table"
              dateOrTimeMissing={this.state.missingDateOrTime}
              updateDateAndTime={this.updateDateAndTimeMap}
            /> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MediaManagement;
