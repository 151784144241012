import { EDIT_TASK, REMOVE_TASK, ADD_TASK, LOAD_TASKS } from "./types";

const loadAutomatedTasks = (tasks) => {
  return {
    type: LOAD_TASKS,
    payload: tasks,
  };
};

const addAutomatedTask = (task) => {
  return {
    type: ADD_TASK,
    payload: task,
  };
};

const editAutomatedTask = (task) => {
  return {
    type: EDIT_TASK,
    payload: task,
  };
};

const removeAutomatedTask = (id) => {
  return {
    type: REMOVE_TASK,
    payload: id,
  };
};

export {
  addAutomatedTask,
  editAutomatedTask,
  removeAutomatedTask,
  loadAutomatedTasks,
};
