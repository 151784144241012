import React, { Component } from "react";
import Select from "react-dropdown-select";
import OPERATORS from "./ConditionOperators";
import { DATA_INDICES } from "./TriggerWrapper";

class ConditionalTrigger extends Component {
  getInterpretableObj = ([blueprints, conditionOperators, triggerValue]) => {
    if (
      blueprints === undefined ||
      conditionOperators[0] === undefined ||
      triggerValue.length < 1
    ) {
      return null;
    }

    return {
      blueprint: blueprints[0].blueprint,
      condition: conditionOperators[0],
      trigger: triggerValue,
    };
  };

  onChangeHandler = (changeEvent, value) => {
    const instantlyUpdatedState = [...this.props.data];
    const { onChange } = this.props;
    const {
      CONDITIONAL: { BLUEPRINT, CONDITION, TRIGGER_VALUE },
    } = DATA_INDICES;

    switch (changeEvent) {
      case CHANGE_EVENTS.BLUEPRINT:
        onChange(BLUEPRINT, value);
        instantlyUpdatedState[BLUEPRINT] = value;
        break;
      case CHANGE_EVENTS.CONDITION:
        onChange(CONDITION, value);
        instantlyUpdatedState[CONDITION] = value;
        break;
      case CHANGE_EVENTS.TRIGGER_VALUE:
        onChange(TRIGGER_VALUE, value);
        instantlyUpdatedState[TRIGGER_VALUE] = value;
        break;
      default:
        return;
    }
    this.props.onInterpret(this.getInterpretableObj(instantlyUpdatedState));
  };

  render() {
    const [blueprints, conditionOperators, triggerValue] = this.props.data;
    const { triggerOptions } = this.props;
    return (
      <>
        <div className="trigger-type-container">
          <i className="fab fa-buffer"></i>
        </div>
        <span className="dropdown-select-container source-container">
          <Select
            closeOnSelect
            placeholder={"Blueprint   "}
            options={triggerOptions} //Actual options that are given with onChange
            labelField={"blueprint.storageName"} //Label used in select is this field
            valueField={"id"} //Option key prop is this field
            noDataLabel={"No sensors found!"}
            values={blueprints}
            onChange={(values) =>
              this.onChangeHandler(CHANGE_EVENTS.BLUEPRINT, values)
            }
          />
        </span>
        <span className="dropdown-select-container condition-container">
          <Select
            closeOnSelect
            searchable={false}
            className={"operators"}
            labelField={"symbolicName"}
            valueField={"name"}
            options={OPERATORS}
            placeholder={"Operator   "}
            noDataLabel={"No operators available!"}
            values={conditionOperators}
            onChange={(values) =>
              this.onChangeHandler(CHANGE_EVENTS.CONDITION, values)
            }
          />
        </span>
        <span className="trigger-value-container">
          <input
            required
            type="text"
            className="form-control"
            placeholder="Trigger Value"
            value={triggerValue}
            onChange={({ target: { value } }) =>
              this.onChangeHandler(CHANGE_EVENTS.TRIGGER_VALUE, value)
            }
          />
        </span>
      </>
    );
  }
}

const CHANGE_EVENTS = {
  BLUEPRINT: "BLP",
  CONDITION: "CON",
  TRIGGER_VALUE: "TRG",
};

export default ConditionalTrigger;
