import React, { Component } from "react";
import "../../../styles/scss/Utils/attached-buttons-group.scss";
class AttachedButtonsGroup extends Component {
  state = {};

  getIcon = (icon) => {
    return icon !== undefined ? (
      <i className={`attached-button-icon fa ${icon}`} />
    ) : (
      ""
    );
  };

  render() {
    const leftButtonObj = this.props.leftButton.getObj();
    const rightButtonObj = this.props.rightButton.getObj();

    return (
      <div className="attached-buttons-group-container">
        <span
          className="btn btn-info left-attached-button"
          type="button"
          onClick={() => {
            leftButtonObj.action();
          }}
        >
          {this.getIcon(leftButtonObj.icon)}
          {leftButtonObj.name}
        </span>

        {this.renderAttachedMidButtons(this.props.midButtons)}

        <span
          className="btn btn-info right-attached-button"
          type="button"
          onClick={() => {
            rightButtonObj.action();
          }}
        >
          {this.getIcon(rightButtonObj.icon)}
          {rightButtonObj.name}
        </span>
      </div>
    );
  }

  renderAttachedMidButtons = (midButtons) => {
    return midButtons !== undefined
      ? midButtons.map((currentButton) => {
          const currentButtonObj = currentButton.getObj();
          return (
            <span
              key={currentButtonObj.name}
              className="btn btn-info mid-attached-button"
              type="button"
              onClick={() => {
                currentButtonObj.action();
              }}
            >
              {this.getIcon(currentButtonObj.icon)}
              {currentButtonObj.name}
            </span>
          );
        })
      : "";
  };
}

class AttachedButtonNode {
  name = undefined;
  action = undefined;
  icon = undefined;

  constructor(name, action, icon) {
    this.name = name;
    this.action = action;
    this.icon = icon;
  }

  getObj = () => {
    return { name: this.name, action: this.action, icon: this.icon };
  };
}

export default AttachedButtonsGroup;
export { AttachedButtonNode };
