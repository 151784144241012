import { combineReducers } from "redux";
import userReducer from "./userReducer";
import dataReducer from "./dataReducer";
import utilsReducer from "./utilsReducer";
import inspectionsReducer from "./inspectionsReducer";
import dateAndTimeReducer from "./dateAndTimeReducer";
import automatedTasksReducer from "./automatedTasksReducer";

export default combineReducers({
  user: userReducer,
  dataset: dataReducer,
  utils: utilsReducer,
  inspections: inspectionsReducer,
  dateAndTime: dateAndTimeReducer,
  automatedTasks: automatedTasksReducer,
});
