import React, { Component } from "react";
import MapWrapper from "../Common/MapWrapper";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

class ArticleContent extends Component {
  article = this.props.article;
  videoPlayBack = require("../../resources/assets/vids/" +
    this.article.body.video.playback);
  videoPoster = require("../../resources/assets/imgs/" +
    this.article.body.video.poster);
  longitude = this.article.body.map.longitude;
  latitude = this.article.body.map.latitude;
  render() {
    return (
      <div>
        <div className="videoArticle">
          <div className="videoRender">
            <video
              src={this.videoPlayBack}
              controls={true}
              loop={true}
              poster={this.videoPoster}
            >
              Unfortunately, your browser doesn't support HTML5.
            </video>
          </div>

          <div className="videoInfo">
            <div className="videoTitle">{this.article.body.article.title}</div>
            <div
              className="videoDescription"
              style={{
                minHeight: "178px",
                maxHeight: "190px",
                overflowY: "auto"
              }}
            >
              {ReactHtmlParser(
                this.article.body.article.body.replace(/\n/g, "<br/>")
              )}
              <br />
            </div>
            {this.article.body.button ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Link to={this.article.body.button.link}>
                  <button
                    type="button"
                    className="btn btn-success btn-md"
                    formTarget={this.article.body.button.link}
                    style={{
                      flexBasis: "100%",
                      marginTop: "10px"
                    }}
                  >
                    <i
                      className={this.article.body.button.icon}
                      aria-hidden="true"
                    />{" "}
                    {this.article.body.button.title}
                  </button>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <MapWrapper lng={this.longitude} lat={this.latitude} />
      </div>
    );
  }
}

export default ArticleContent;
