const ROOT_CLASSNAMES = { auth: "login-body", home: "animated-body" };

const clearRootElementClasses = () => {
  const rootElement = document.getElementById("root");
  rootElement.classList.forEach((classItem) => {
    rootElement.classList.remove(classItem);
  });
};

const setRootElementClass = (className) => {
  const rootElement = document.getElementById("root");
  clearRootElementClasses();
  if (className === undefined) {
    console.error("Invalid root class provided!");
  } else {
    rootElement.classList.add(className);
  }
};

export { ROOT_CLASSNAMES, clearRootElementClasses, setRootElementClass };
