export const extractUsernameFromEmail = (username) => {
  const indexOfEmailSymbol = username.indexOf("@");
  if (indexOfEmailSymbol === 0 || indexOfEmailSymbol === -1) return username;
  return username.substring(0, indexOfEmailSymbol);
};

export const isBlank = (target) => {
  if (typeof target !== "string") {
    return target === undefined;
  }
  const testString = target.trim();
  return !testString || /^\s*$/.test(testString);
};
