import React from "react";
import {
  Map,
  DefaultContainerElementConfig,
  DefaultMapElementConfig,
  DefaultLoadingElementConfig,
  DefaultGoogleMapURL,
  CustomContainerElementConfig
} from "./Map";

const MapWrapper = ({ height, width, lng, lat }) => {
  return height === undefined && width === undefined ? (
    <Map
      lng={lng}
      lat={lat}
      loadingElement={DefaultLoadingElementConfig}
      googleMapURL={DefaultGoogleMapURL}
      containerElement={DefaultContainerElementConfig}
      mapElement={DefaultMapElementConfig}
    />
  ) : (
    /*  For custom containers we should provide the size in px, ex. height={"300px"} not height={"300"}*/
    <Map
      lng={lng}
      lat={lat}
      loadingElement={DefaultLoadingElementConfig}
      googleMapURL={DefaultGoogleMapURL}
      containerElement={CustomContainerElementConfig({
        width: width,
        height: height
      })}
      mapElement={DefaultMapElementConfig}
    />
  );
};

export default MapWrapper;
