import { INPUT_TYPES } from "./PolyInput/PolyInputConstants";
import DerivedBlueprintsMenu from "./DerivedBlueprintsMenu";

const BLUEPRINT_TYPE_CATEGORIES = {
  ACTION: "ACTION",
  SENSOR: "SENSOR",
};

const BLUEPRINT_TYPES = [
  {
    name: "GENERIC",
    category: BLUEPRINT_TYPE_CATEGORIES.SENSOR,
    fields: [],
  },
  {
    name: "DERIVED",
    category: BLUEPRINT_TYPE_CATEGORIES.SENSOR,
    fields: [
      {
        fieldName: "Source Blueprints",
        inputType: INPUT_TYPES.CUSTOM,
        customComponent: DerivedBlueprintsMenu,
        placeholder: "Select a source blueprint",
      },
    ],
  },
  {
    name: "MODBUS",
    category: BLUEPRINT_TYPE_CATEGORIES.SENSOR,
    fields: [
      {
        fieldName: "Channel",
        inputType: INPUT_TYPES.NUMBER,
        maxLength: 8,
        placeholder: "Channel Number",
      },
    ],
  },
  {
    name: "API",
    category: BLUEPRINT_TYPE_CATEGORIES.SENSOR,
    fields: [
      {
        fieldName: "API Endpoint URL",
        inputType: INPUT_TYPES.TEXT,
        maxLength: 100,
      },
    ],
  },
  {
    name: "MQTT SENSOR",
    category: BLUEPRINT_TYPE_CATEGORIES.SENSOR,
    fields: [
      {
        fieldName: "MQTT Topic",
        inputType: INPUT_TYPES.TEXT,
        maxLength: 140,
      },
      {
        fieldName: "Sensor Name",
        inputType: INPUT_TYPES.TEXT,
        maxLength: 100,
      },
    ],
  },
  {
    name: "MQTT SWITCH",
    category: BLUEPRINT_TYPE_CATEGORIES.ACTION,
    fields: [
      {
        fieldName: "MQTT Topic",
        inputType: INPUT_TYPES.TEXT,
        maxLength: 140,
      },
      {
        fieldName: "Switch Name",
        inputType: INPUT_TYPES.TEXT,
        maxLength: 100,
      },
    ],
  },
  {
    name: "ARDUINO",
    category: BLUEPRINT_TYPE_CATEGORIES.ACTION,
    fields: [
      {
        fieldName: "Relay",
        inputType: INPUT_TYPES.TEXT,
        maxLength: 100,
        placeholder: "RELAY_PRIMARY",
      },
    ],
  },
  {
    name: "EMAIL",
    category: BLUEPRINT_TYPE_CATEGORIES.ACTION,
    fields: [
      {
        fieldName: "Email",
        inputType: INPUT_TYPES.TEXT,
        maxLength: 100,
        placeholder: "john_doe@provider.com",
      },
    ],
  },
];

const getTypeObjByString = (string) => {
  return BLUEPRINT_TYPES.filter((type) => {
    return type.name === string;
  })[0];
};

const getFieldValuesArray = (type) => {
  const { fields } = type;
  const fieldsLength = fields.length;
  return new Array(fieldsLength);
};

const getFieldInfoArray = (type) => {
  return type.fields;
};

export {
  BLUEPRINT_TYPES,
  BLUEPRINT_TYPE_CATEGORIES,
  getFieldValuesArray,
  getFieldInfoArray,
  getTypeObjByString,
};
