import axios from "axios";
import {
  LOGIN_ENDPOINT,
  MESSAGE_FAILURE,
  MESSAGE_SUCCESS,
  REGISTER_ENDPOINT,
  JSON_HEADER_CACHABLE,
  MESSAGE_ERROR,
} from "./api-info";
import Store from "../store";
import { updateUserToken } from "../actions/userActions";

export const loginAndRetrieveToken = (username, password) => {
  const payload = JSON.stringify({
    username: username,
    password: password,
  });

  return axios
    .post(LOGIN_ENDPOINT, payload, JSON_HEADER_CACHABLE)
    .then((res) => {
      if (
        res.data.status === MESSAGE_ERROR ||
        res.data.username === undefined
      ) {
        throw Error("Wrong username OR Password");
      }
      const authenticationToken = res.headers["x-auth"];
      if (authenticationToken !== undefined) {
        Store.dispatch(updateUserToken(authenticationToken));
      }
      return {
        status: MESSAGE_SUCCESS,
        payload: {
          username: res.data.username,
          token: authenticationToken,
        },
      };
    })
    .catch((e) => {
      return { status: MESSAGE_FAILURE, errorMessage: e };
    });
};

export const registerNewUser = (username, password, fullname) => {
  const payload = JSON.stringify({
    username: username,
    password: password,
    fullname: fullname,
  });

  return axios
    .post(REGISTER_ENDPOINT, payload, JSON_HEADER_CACHABLE)
    .then((res) => {
      if (res.data.status === "SUCCESS") {
        return {
          status: MESSAGE_SUCCESS,
        };
      } else if (res.data.status === "ERROR") {
        return { status: MESSAGE_FAILURE, errorMessage: res.data.errorMessage };
      }
    })
    .catch((e) => {
      return { status: MESSAGE_FAILURE, errorMessage: e };
    });
};

export const logout = () => {
  Store.dispatch(updateUserToken(""));
};
