import React, { Component } from "react";
import "../styles/scss/Common/error.scss";

class Error extends Component {
  render() {
    return (
      <div id="error">
        <div className="error">
          <div className="error-message">
            <h1>Oops!</h1>
            <h2>
              {this.props.errorCode} - {this.props.errorTitle}
            </h2>
          </div>
          <a href="/">Go TO HOMEPAGE</a>
        </div>
      </div>
    );
  }
}

export default Error;
