import React, { Component } from "react";

class Record extends Component {
  state = {};
  render() {
    const record = this.props.record;
    const icon = "fa-2x " + record.icon;
    return (
      <div className="record-container">
        <div className="info-card">
          <div className="info-card-icon">
            <i className={icon} aria-hidden="true" />
          </div>
          <div className="info-card-title">{record.name}</div>
          <div className="info-card-value">{record.value}</div>
        </div>
      </div>
    );
  }
}

export default Record;
