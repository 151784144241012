class Observable {
  constructor(...observers) {
    this.observers = [];
    observers.forEach(observer => {
      this.observers.push(observer);
    });
  }

  subscribe(observer) {
    this.observers.push(observer);
  }

  unsubscribe(observer) {
    this.observers = this.observers.filter(
      currentObserver => currentObserver !== observer
    );
  }

  notify(operation, payload, serializeOperations) {
    this.observers.forEach(observer => {
      observer.callHandlerFunction(operation, payload, serializeOperations);
    });
  }
}

export default Observable;
