import React, { Component } from "react";
import SideBar from "./SideBar";
import Body from "./CoreBody";
import { connect } from "react-redux";
import { setLoading } from "../../actions/utilsActions";
import { clearRootElementClasses } from "../utils/root-element-utils";

class Dashboard extends Component {
  constructor() {
    super();
    clearRootElementClasses();
  }

  cancelLoading = (e) => {
    this.props.loadingState(false);
  };

  render() {
    return (
      <div className="container-fluid display-table">
        {this.props.loading && (
          <div className="loading" onClick={(e) => this.cancelLoading(e)}>
            <div className="loadingContainer">
              <i className="fas fa-power-off fa-7x" />
            </div>
          </div>
        )}
        <div className="row display-table-row">
          <SideBar links={this.props.sidelinks} />
          <Body>{this.props.children}</Body>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { loading: state.utils.loading };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadingState: (loadingState) => {
      dispatch(setLoading(loadingState));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
