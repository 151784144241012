class MemoryBlock {
  constructor(memoryBlockType, payload) {
    this.memoryBlockType = memoryBlockType;
    this.payload = payload;
  }

  setMemoryBlockType(memoryBlockType) {
    this.memoryBlockType = memoryBlockType;
  }

  setPayload(payload) {
    this.payload = payload;
  }

  getMemoryBlockObj() {
    return {
      memoryBlockType: this.memoryBlockType,
      payload: this.payload
    };
  }
}

export default MemoryBlock;
