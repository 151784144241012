/* User-Related Actions */
export const UPDATE_USER_TOKEN = "updateUserToken";
export const USER_LOGIN = "userLogin";
export const USER_LOGOUT = "userLogout";
export const UPDATE_USERNAME = "updateUsername";
export const UPDATE_PUNISHMENT = "updatePunishment";

/* Data-Related Actions */
export const UPDATE_DATA = "updateData";

/* Utils-Related Actions */
export const SET_LOADING = "setLoading";

/* Scheme-Related Actions */
export const ADD_INSPECTION = "addInspection";
export const REMOVE_INSPECTION = "removeInspection";

/* Date&Time-Related Actions */
export const SET_START_DATE = "setStartDate";
export const SET_END_DATE = "setEndDate";
export const SET_START_TIME = "setStartTime";
export const SET_END_TIME = "setEndTime";

/* Automated Tasks Actions */
export const LOAD_TASKS = "loadAutomatedTasks";
export const ADD_TASK = "addAutomatedTask";
export const EDIT_TASK = "editAutomatedTask";
export const REMOVE_TASK = "removeAutomatedTask";
