import React, { Component } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const inlineStyling = {
  icon: {
    display: "inline",
    marginLeft: "10px",
    color: "#4caf50",
    marginTop: "2px"
  },
  text: {
    display: "inline-block",
    width: "100%"
  }
};
class SideLink extends Component {
  state = { active: false };

  render() {
    const { icon, route, children, active, enabled } = this.props;
    const iconClass = "fa " + icon;
    const dataToggle = isMobile ? "collapse" : "";
    const dataTarget = isMobile ? "#side-bar-links" : "";

    return (
      enabled && (
        <Link to={route} data-toggle={dataToggle} data-target={dataTarget}>
          <li data-active={active}>
            <i className={iconClass} style={inlineStyling.icon} />
            <span style={inlineStyling.text}>{children}</span>
          </li>
        </Link>
      )
    );
  }
}

export default SideLink;
