import React, { Component } from "react";
import SectionTitle from "../Common/SectionTitle";
import { Link } from "react-router-dom";
import TaskCard from "./TaskCard";
class TasksDashboard extends Component {
  
  componentDidMount(){
	  this.props.loadTasks();
  }

  renderTaskCards = () => {
    const { tasks, onTaskRemove } = this.props;
    return tasks.map(({ id, icon, title, interpretation }) => {
      return (
        <TaskCard
          id={id}
          key={id}
          icon={icon}
          title={title}
          conditions={interpretation[0]}
          actions={interpretation[1]}
          onDelete={onTaskRemove}
        />
      );
    });
  };

  render() {
    return (
      <div className="tasks-management-container">
        <SectionTitle title={"Tasks Management"} />
        <section id="tasks-container" className="row">
          {this.renderTaskCards()}
        </section>
        <Link id="add-new-task-btn" to="tasks/tcp">
          <i className="fas fa-plus-square"></i>
        </Link>
      </div>
    );
  }
}

export default TasksDashboard;
