import React, { Component } from "react";

class DataBarIcon extends Component {
  render() {
    const record = this.props.record;
    const icon = "status-icon " + record.icon;
    return (
      <li title={record.name}>
        <i className={icon} aria-hidden="true" />
        <span>{record.value}</span>
      </li>
    );
  }
}

export default DataBarIcon;
