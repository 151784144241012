import axios from "axios";
import {
  GET_LATEST_RECORDS_ENDPOINT,
  GET_RANGE_RECORDS_ENDPOINT,
  MESSAGE_SUCCESS,
  MESSAGE_FAILURE,
  MESSAGE_ERROR,
  JSON_HEADER_NOCACHE
} from "./api-info";

export const getDataByTimeAndDateRequest = dateAndTime => {
  const payload = JSON.stringify(dateAndTime);

  return axios
    .post(GET_RANGE_RECORDS_ENDPOINT, payload, JSON_HEADER_NOCACHE)
    .then(res => {
      if (res.data.data.length > 0) {
        return {
          status: MESSAGE_SUCCESS,
          payload: res.data
        };
      } else {
        return { status: MESSAGE_FAILURE, payload: res.data.errorMessage };
      }
    })
    .catch(e => {
      // console.log({
      //   status: MESSAGE_ERROR,
      //   errorMessage: "Records not found!"
      // });
    });
};

export const getLatestRecordsRequest = () => {
  return axios
    .get(GET_LATEST_RECORDS_ENDPOINT, {}, JSON_HEADER_NOCACHE)
    .then(res => {
      if (res.data.data.length > 0) {
        return {
          status: MESSAGE_SUCCESS,
          payload: res.data
        };
      } else {
        return { status: MESSAGE_FAILURE, payload: res.data.errorMessage };
      }
    })
    .catch(e => {
      return { status: MESSAGE_ERROR, errorMessage: e };
    });
};
