import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  userLogin,
  updateUsername,
  updateUserToken,
} from "../../actions/userActions";
import { setLoading } from "../../actions/utilsActions";
import { registerNewUser } from "../../api/userapi";
import { Link } from "react-router-dom";
import { MESSAGE_FAILURE, MESSAGE_SUCCESS } from "../../api/api-info";
import "../../styles/scss/Auth/login.scss";
import { validate } from "email-validator";
class RegisterPanel extends Component {
  state = { errorMessage: undefined, loading: false };

  onSubmit = (event) => {
    event.preventDefault();
    const username = this.usernameInput.value;
    const password = this.passwordInput.value;
    const fullname = this.fullnameInput.value;
    if (!validate(username)) {
      this.setState({ errorMessage: "Invalid email" });
    } else if (password.length < 8) {
      this.setState({ errorMessage: "Password is too weak" });
    } else if (fullname.length < 5) {
      this.setState({ errorMessage: "Invalid fullname!" });
    } else {
      this.setState({ loading: true });
      let registerRequest = registerNewUser(username, password, fullname);
      registerRequest.then((data) => {
        if (data.status === MESSAGE_FAILURE) {
          this.setState({ errorMessage: data.errorMessage });
        } else if (data.status === MESSAGE_SUCCESS) {
          this.setState({ errorMessage: undefined });
          this.props.history.push("/login");
        }
      });
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <form className="form-container" onSubmit={this.onSubmit}>
        <div className="logo">
          <Link to={"/"} className="logo" style={{ textDecoration: "none" }}>
            Earth Station Modular
          </Link>
        </div>
        {this.state.errorMessage !== undefined && (
          <div className="alert alert-danger" style={{ flexBasis: "100%" }}>
            <strong>Error: </strong>
            {this.state.errorMessage}
          </div>
        )}
        <React.Fragment>
          <div className="form-group input-block">
            <label htmlFor="username">Email</label>
            <input
              id="username"
              placeholder="user@emailservice.com"
              className="form-control"
              style={this.textInput}
              ref={(input) => (this.usernameInput = input)}
            />
          </div>
          <div className="form-group input-block">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              className="form-control"
              placeholder="*************************"
              ref={(input) => {
                this.passwordInput = input;
              }}
            />
          </div>
          <div className="form-group input-block">
            <label htmlFor="fullname">Fullname</label>
            <input
              id="fullname"
              type="text"
              className="form-control"
              placeholder="John Doe"
              ref={(input) => {
                this.fullnameInput = input;
              }}
            />
          </div>
          <button
            className="btn btn-info btn-lg register-button"
            type="submit"
            onClick={this.onClick}
            disabled={this.state.loading}
          >
            {ButtonLoadingSpinner(this.state.loading)}
            Register
          </button>
          <Link to="/login" className="button-text">
            <button
              type="button"
              className="btn btn-danger btn-lg cancel-button"
            >
              Cancel
            </button>
          </Link>
        </React.Fragment>
      </form>
    );
  }
}

const ButtonLoadingSpinner = (loading) => {
  return (
    loading && (
      <i
        className="fas fa-circle-notch fa-spin"
        style={{ margin: " 0px 5px" }}
      />
    )
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: () => {
      dispatch(userLogin());
    },
    updateUsername: (username) => {
      dispatch(updateUsername(username));
    },
    updateUserToken: (token) => {
      dispatch(updateUserToken(token));
    },
    setLoading: (loadingState) => {
      dispatch(setLoading(loadingState));
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(RegisterPanel));
