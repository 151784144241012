const objectKeysToOneArray = payload => {
  if(payload === undefined){
    console.log(payload);
    return payload;
  }
  return Object.entries(payload);
};

export const mapToArray = map => {
  const serializedArray = [];
  map.forEach((value, key) => {
    const keysToArray = objectKeysToOneArray(key);
    const keyValueArray = [keysToArray, value];
    serializedArray.push(keyValueArray);
  });
  return serializedArray;
};
