import React, { Component } from "react";

class TypeSpecField extends Component {
  state = { value: "" };

  onChangeHandler = (event) => {
    const value = event.target.value;
    const { onFieldUpdate, index } = this.props;
    this.setState({ value: value });
    onFieldUpdate(index, value);
  };

  resetField = () => {
    this.setState({
      value: "",
    });
  };

  render() {
    const { htmlName, name, placeholder, maxLength } = this.props;

    return (
      <div key={name} className="form-group col">
        <label htmlFor={htmlName}>{name}</label>
        <input
          className="form-control"
          name={htmlName}
          type="text"
          placeholder={placeholder}
          value={this.state.value}
          onChange={(e) => this.onChangeHandler(e)}
          maxLength={maxLength}
        />
      </div>
    );
  }
}

export default TypeSpecField;
