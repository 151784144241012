import React from "react";
import "../../styles/scss/Tasks/tasks.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TaskControlPanel, { TASK_CP_MODE } from "./AddTask/TaskControlPanel";
import TasksDashboard from "./TasksDashboard";
import { loadTasks, addTask, deleteTask, editTask } from "../../api/tasksapi";

const TasksManagement = ({ match: { path }, tasks }) => {
  return (
    <Switch>
      <Route
        path={`${path}/tcp/:taskID`}
        render={(props) => (
          <TaskControlPanel
            {...props}
            mode={TASK_CP_MODE.EDIT}
            tasks={tasks}
            onTaskSubmit={editTask}
          />
        )}
      />
      <Route
        path={`${path}/tcp`}
        exact
        render={(props) => (
          <TaskControlPanel
            {...props}
            mode={TASK_CP_MODE.ADD}
            onTaskSubmit={addTask}
          />
        )}
      />
      <Route
        path={`${path}`}
        exact
        render={(props) => (
          <TasksDashboard
            {...props}
            loadTasks={loadTasks}
            tasks={tasks}
            onTaskRemove={deleteTask}
          />
        )}
      />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  tasks: state.automatedTasks.tasks,
});

export default connect(mapStateToProps)(TasksManagement);
