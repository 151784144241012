import { TRIGGER_TYPES } from "./AddTask/Triggers/TriggerConstants";

/**
 * @param {Object[]} triggers - Triggers List
 * @param {Object[]} actions -  Actions List
 */
const interpret = (
  triggers,
  actions,
  mode = INTERPRETATION_MODES.SINGLE_STRING_INTERPRET
) => {
  if (!interpretableTriggersAndActions(triggers, actions)) {
    return "Please fill all trigger and action values to recieve the interpretation.";
  }

  const chronicalTriggers = triggers.filter(
    ({ type }) => type === TRIGGER_TYPES.CHRONICAL
  );
  const chronicalExist = chronicalTriggers.length > 0;
  const conditionalsExist = triggers.length - chronicalTriggers.length > 0;

  let activationInterpretation = chronicalExist
    ? chronicalInterpretation(chronicalTriggers[0])
    : "";
  if (conditionalsExist) {
    if (chronicalExist) {
      activationInterpretation += ", also when ";
    } else {
      activationInterpretation += "When ";
    }
  }
  let conditionalCounter = 0;
  const pureTriggersLength = chronicalExist
    ? triggers.length - 1
    : triggers.length;

  triggers.forEach(({ type, data }) => {
    if (type !== TRIGGER_TYPES.CHRONICAL) {
      activationInterpretation += conditionalInterpretation(
        data,
        conditionalCounter,
        pureTriggersLength
      );
      conditionalCounter++;
    }
  });

  //   let invokationInterpretation = conditionalsExist ? ` ,Then` : " ,";
  let invokationInterpretation = ", ";
  let actionsCounter = 0;

  actions.forEach((action) => {
    invokationInterpretation += actionInterpretation(
      action,
      actionsCounter,
      actions.length
    );
    actionsCounter += 1;
  });
  invokationInterpretation += " will be invoked.";

  if (mode === INTERPRETATION_MODES.DIVIDED_ARRAY_INTERPRET) {
    const dividedInvokationInterpretation = invokationInterpretation.slice(2);
    return [activationInterpretation, dividedInvokationInterpretation];
  }
  return activationInterpretation + invokationInterpretation;
};

const conditionalInterpretation = (
  { blueprint: { storageName }, condition: { interpretation }, trigger },
  counter,
  pureLength
) => {
  if (counter === 0) {
    return `${storageName} ${interpretation} ${trigger}`;
  } else {
    if (counter < pureLength - 1) {
      return `, ${storageName} ${interpretation} ${trigger}`;
    } else {
      return ` and ${storageName} ${interpretation} ${trigger}.`;
    }
  }
};

/*
@private
*/
const chronicalInterpretation = ({ data: { hours, minutes, seconds } }) => {
  let interpretation = "Every ";
  let firstElementPlaced = false;

  if (interpretableTiming(hours)) {
    firstElementPlaced
      ? (interpretation += `, ${hours} Hours`)
      : (interpretation += `${hours} Hours`);
    firstElementPlaced = true;
  }

  if (interpretableTiming(minutes)) {
    firstElementPlaced
      ? (interpretation += `, ${minutes} Minutes`)
      : (interpretation += `${minutes} Minutes`);
    firstElementPlaced = true;
  }

  if (interpretableTiming(seconds)) {
    firstElementPlaced
      ? (interpretation += `, ${seconds} Seconds`)
      : (interpretation += `${seconds} Seconds`);
    firstElementPlaced = true;
  }
  return interpretation;
};

const actionInterpretation = (
  { blueprint: { storageName } },
  counter,
  length
) => {
  if (counter === 0) {
    return `${storageName}`;
  } else if (counter < length - 1) {
    return `, ${storageName}`;
  } else {
    return ` and ${storageName}`;
  }
};

const interpretableTiming = (timingValue) => {
  return (
    timingValue !== undefined &&
    timingValue !== null &&
    timingValue !== 0 &&
    timingValue.length > 0
  );
};

const interpretableTriggersAndActions = (triggers, actions) => {
  return (
    triggers.length > 0 &&
    triggers.filter(({ type, data }) => {
      if (data) {
        if (type === TRIGGER_TYPES.CHRONICAL) {
          const { hours, minutes, seconds } = data;
          return (
            hours === undefined &&
            minutes === undefined &&
            seconds === undefined
          );
        }

        const {
          blueprint: { storageName },
          condition,
          trigger,
        } = data;

        return (
          storageName === undefined ||
          condition === undefined ||
          trigger === undefined
        );
      } else {
        return false;
      }
    }).length === 0 &&
    actions.length > 0 &&
    actions.filter(
      ({ blueprint: { storageName } }) => storageName === undefined
    ).length === 0
  );
};

const INTERPRETATION_MODES = {
  SINGLE_STRING_INTERPRET: "SSI",
  DIVIDED_ARRAY_INTERPRET: "DAI",
};

export { interpret as default, INTERPRETATION_MODES };
