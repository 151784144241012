import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Error from "./pages/Error";
import Home from "./pages/Home";
import Article from "./pages/Article";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PublicPages from "./datasources/public-pages";
import UserControlPanel from "./pages/UserControlPanel";
import { Provider } from "react-redux";
import Store from "./store";
import Logout from "./components/Auth/LogoutGateway";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import axios from "axios";
import UserPages from "./datasources/ucp-pages";

/* eslint-disable */
class App extends Component {
  articleRoutes = "/(";
  constructor() {
    super();
    const rootElement = document.getElementById("root");
    rootElement.classList.add("animated-body");

    PublicPages.pages.map((element) => {
      this.articleRoutes += element.header.route.slice(1) + "|";
    });
    this.articleRoutes =
      this.articleRoutes.slice(0, this.articleRoutes.length - 1) + ")";
  }

  reloadedPageResetup() {
    const authenticationToken = Store.getState().user.token;
    if (authenticationToken !== undefined && authenticationToken.length > 5) {
      axios.defaults.headers.common["X-Auth"] = Store.getState().user.token;
    }
  }

  loadUcpFullRoute() {
    const UCP_BASE_ROUTE = "/ucp/";
    let fullRoute = UCP_BASE_ROUTE + "(";
    UserPages.pages.forEach((page) => {
      if (page.header.enabled) {
        fullRoute += page.header.routeName + "|";
      }
    });
    fullRoute += ")";
    return fullRoute;
  }

  render() {
    this.reloadedPageResetup();
    return (
      <Provider store={Store}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact render={(routeProps) => <Home />} />
            <Route path={this.articleRoutes} exact component={Article} />
            <ProtectedRoute path={this.loadUcpFullRoute()}>
              <UserControlPanel />
            </ProtectedRoute>
            <Route path={"/login*"} component={Login} />
            <Route path={"/register*"} component={Register} />
            <Route path="/logout*" component={Logout} />
            <Route
              render={(routeProps) => (
                <Error errorCode="404" errorTitle="Page can't be found!" />
              )}
            />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
