import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  userLogin,
  updateUsername,
  updateUserToken,
  updatePunishment,
} from "../../actions/userActions";
import { setLoading } from "../../actions/utilsActions";
import { getRemainingPunishmentTime } from "../utils/date-time-utils";
import { loginAndRetrieveToken } from "../../api/userapi";
import { Link } from "react-router-dom";
import { MESSAGE_SUCCESS } from "../../api/api-info";
import "../../styles/scss/Auth/login.scss";
import moment from "moment";

class LoginPanel extends Component {
  state = { remainingAttempts: 100, punishmentTime: -1, loading: false };
  MAXIMUM_ATTEMPTS = 100;
  MAXIMUM_PUNISHMENT_TIME = 10;

  onSubmit = (event) => {
    event.preventDefault();
    const username = this.usernameInput.value;
    const password = this.passwordInput.value;

    if (this.state.remainingAttempts <= 0) {
      return;
    }

    this.setState({ loading: true });
    let loginRequest = loginAndRetrieveToken(username, password);

    loginRequest.then((data) => {
      this.setState({ loading: false });
      if (data.status === MESSAGE_SUCCESS) {
        this.props.login();
        this.props.updateUserToken(data.payload.token);
        this.props.updateUsername(data.payload.username);
        this.props.history.push("/ucp/analytics");
      } else {
        if (this.state.remainingAttempts < 1) {
          this.props.updatePunishment(moment());
          this.updateLocalPunishment();
        }
        this.setState({ remainingAttempts: this.state.remainingAttempts - 1 });
      }
    });
  };

  updateLocalPunishment = () => {
    const timeDifference = getRemainingPunishmentTime(
      this.props.punishmentStartTime
    );
    this.setState({ punishmentTime: timeDifference });
  };

  render() {
    return (
      <form className="form-container" onSubmit={this.onSubmit}>
        <div className="logo">
          <Link to={"/"} className="logo" style={{ textDecoration: "none" }}>
            Earth Station Modular
          </Link>
        </div>
        {this.state.remainingAttempts > 0 &&
        this.state.remainingAttempts < this.MAXIMUM_ATTEMPTS ? (
          <div className="alert alert-danger" style={{ flexBasis: "100%" }}>
            <strong>Access Denied:</strong> Wrong username/password!
          </div>
        ) : (
          ""
        )}
        <React.Fragment>
          <div className="form-group input-block">
            <label htmlFor="username">Email</label>
            <input
              id="username"
              placeholder="user@emailservice.com"
              className="form-control"
              style={this.textInput}
              ref={(input) => (this.usernameInput = input)}
            />
          </div>
          <div className="form-group input-block">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              className="form-control"
              placeholder="*************************"
              ref={(input) => {
                this.passwordInput = input;
              }}
            />
          </div>
          {this.state.remainingAttempts > 0 &&
          this.state.remainingAttempts < this.MAXIMUM_ATTEMPTS ? (
            <div className="forgot-password">
              Forgot your password?<a href="##"> Here</a>
            </div>
          ) : (
            ""
          )}
          <button
            className="btn btn-info btn-lg login-button"
            type="submit"
            onClick={this.onClick}
            disabled={this.state.loading}
          >
            {ButtonLoadingSpinner(this.state.loading)}
            Login
          </button>
          <div className="register">
            Don't have an account? <Link to="/register">Register Here</Link>
          </div>
        </React.Fragment>
      </form>
    );
  }
}

const ButtonLoadingSpinner = (loading) => {
  return (
    loading && (
      <i
        className="fas fa-circle-notch fa-spin"
        style={{ margin: " 0px 5px" }}
      />
    )
  );
};

const mapStateToProps = (state) => {
  return {
    punishmentStartTime: state.user.punishment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: () => {
      dispatch(userLogin());
    },
    updateUsername: (username) => {
      dispatch(updateUsername(username));
    },
    updateUserToken: (token) => {
      dispatch(updateUserToken(token));
    },
    setLoading: (loadingState) => {
      dispatch(setLoading(loadingState));
    },
    updatePunishment: (punishmentTime) => {
      dispatch(updatePunishment(punishmentTime));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPanel));
