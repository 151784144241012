import React, { Component } from "react";
import { convertTimeFromGmtToLocal } from "../../utils/date-time-utils";

class TableRecord extends Component {
  state = {};
  render() {
    return (
      <tr>
        <th scope="row">{this.props.record.date}</th>
        <th scope="row">{convertTimeFromGmtToLocal(this.props.record.time)}</th>
        <td>{this.props.record.value}</td>
      </tr>
    );
  }
}

export default TableRecord;
