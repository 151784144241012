import React, { Component } from "react";
import TriggerWrapper from "./TriggerWrapper";
import { TRIGGER_TYPES } from "./TriggerConstants";
import { v4 as uuid } from "uuid";
import SubsectionTitle from "../SubsectionTitle";

class TriggersContainer extends Component {
  state = {
    // triggers - array of string containg trigger type
    triggers: [],
    maxChronicalTriggersExceeded: false,
  };

  initTriggers = (triggers) => {
    this.setState({ triggers });
  };

  injectDataIntoTrigger = (index, data) => {
    const triggers = [...this.state.triggers];
    triggers[index].data = data;
    this.props.onTriggersCompiled(triggers);
    this.setState({ triggers: triggers });
  };

  addTrigger = (type) => {
    let tmpTriggerType = null;
    const triggers = [...this.state.triggers];
    switch (type) {
      case TRIGGER_TYPES.CONDITIONAL:
        tmpTriggerType = { type: TRIGGER_TYPES.CONDITIONAL, id: uuid() };
        break;
      case TRIGGER_TYPES.CHRONICAL:
        this.setState({ maxChronicalTriggersExceeded: true });
        tmpTriggerType = { type: TRIGGER_TYPES.CHRONICAL, id: uuid() };
        break;
      default:
        return;
    }
    const tmpTriggersList = triggers;
    tmpTriggersList.unshift(tmpTriggerType);
    this.setState({ triggers: tmpTriggersList });
  };

  onRemoveTrigger = (index) => {
    const tmpTriggersList = [...this.state.triggers];
    if (tmpTriggersList[index].type === TRIGGER_TYPES.CHRONICAL) {
      this.setState({ maxChronicalTriggersExceeded: false });
    }
    tmpTriggersList.splice(index, 1);
    this.props.onTriggersCompiled(tmpTriggersList);
    this.setState({ triggers: tmpTriggersList });
  };

  renderTriggers = () => {
    const { triggers } = this.state;
    return triggers.map((trigger, index) => {
      switch (trigger.type) {
        case TRIGGER_TYPES.CONDITIONAL:
          return (
            <TriggerWrapper
              id={trigger.id}
              key={trigger.id}
              type={TRIGGER_TYPES.CONDITIONAL}
              index={index}
              ZIndex={triggers.length - index}
              initialData={trigger.data}
              onInjectCompiledData={this.injectDataIntoTrigger}
              onDelete={this.onRemoveTrigger}
            />
          );
        case TRIGGER_TYPES.CHRONICAL:
          return (
            <TriggerWrapper
              id={trigger.id}
              key={trigger.id}
              type={TRIGGER_TYPES.CHRONICAL}
              index={index}
              ZIndex={triggers.length - index}
              initialData={trigger.data}
              onInjectCompiledData={this.injectDataIntoTrigger}
              onDelete={this.onRemoveTrigger}
            />
          );
        default:
          return "";
      }
    });
  };

  render() {
    const listLength = this.state.triggers.length;
    return (
      <>
        <TriggersListHeader />
        <ul>
          {listLength > 0 ? this.renderTriggers() : <TriggersListPlaceholder />}
        </ul>
        <AddTriggerButtons
          onClickHandler={this.addTrigger}
          chronicalAvailable={this.state.maxChronicalTriggersExceeded}
        />
      </>
    );
  }
}

const TriggersListPlaceholder = () => {
  return <h2 id="triggers-list-placeholder">No triggers created yet!</h2>;
};

const TriggersListHeader = () => {
  return <SubsectionTitle title={"Triggers"} />;
};

const AddTriggerButtons = ({ onClickHandler, chronicalAvailable }) => {
  return (
    <div className="add-trigger-container">
      <button
        type="conditional"
        onClick={() => onClickHandler(TRIGGER_TYPES.CONDITIONAL)}
      >
        <i className="fab fa-buffer"></i>
        <span>Add Conditional Trigger</span>
      </button>
      <button
        type="chronical"
        onClick={() => onClickHandler(TRIGGER_TYPES.CHRONICAL)}
        disabled={chronicalAvailable}
      >
        <i className="fas fa-history"></i>
        <span>Add Chronical Trigger</span>
      </button>
    </div>
  );
};

export default TriggersContainer;
