import React, { Component } from "react";
import DateTimePicker from "../DateTimePickerWrapper";
import { connect } from "react-redux";
import { setLoading } from "../../../actions/utilsActions";
import { getSchemeRequest } from "../../../api/schemeapi";
import { groupRecordsByBlueprintsInArray } from "../../utils/data-structure-utils";
import { generateTableData } from "../../utils/chart-dataset-generator";
import { getDataByTimeAndDateRequest } from "../../../api/recordapi";
import { mapToObject } from "../../utils/map-utils";
import { MESSAGE_SUCCESS } from "../../../api/api-info";
import TableRecord from "./TableRecord";
import { OPEN_UP } from "react-dates/lib/constants";

class TablePanel extends Component {
  state = {
    data: undefined,
    blueprints: [],
    labels: [""],
    emptyTable: true,
    tableMessage: "No data fetched yet",
  };

  dateAndTimeMap = new Map();

  componentDidMount() {
    this.getScheme();
  }

  getScheme = () => {
    this.props.loadingState(true);
    const schemeRequest = getSchemeRequest();
    schemeRequest
      .then((response) => response.payload)
      .then((data) => {
        if (data !== undefined) {
          this.setState({ blueprints: data.scheme.blueprints });
        }
      })
      .finally((res) => {
        this.props.loadingState(false);
      });
  };

  createTableData = (rawData, selectedVariables) => {
    const labelsAndBlueprints = groupRecordsByBlueprintsInArray(rawData);
    const datasets = generateTableData(
      labelsAndBlueprints.labels,
      rawData.data,
      labelsAndBlueprints.data,
      labelsAndBlueprints.blueprints,
      selectedVariables
    );
    return datasets;
  };

  renderTableData = (e) => {
    e.persist();
    const eventDispatcher = e.target;
    const selectedVariables = [eventDispatcher.value];
    const dateAndTimeObj = mapToObject(this.dateAndTimeMap);
    if (this.emptyDateOrTime(this.dateAndTimeMap)) {
      this.setState({ missingDateOrTime: true });
    } else {
      const data = getDataByTimeAndDateRequest(dateAndTimeObj);
      this.setState({ missingDateOrTime: false });
      data.then((res) => {
        if (res !== undefined) {
          if (res.status === MESSAGE_SUCCESS) {
            const rawData = res.payload;
            const tableData = this.createTableData(rawData, selectedVariables);
            this.setState({
              labels: tableData.labels,
              data: tableData.datasets,
            });
          }
        } else {
          this.setState({
            tableMessage: "No records found!",
          });
        }
      });
    }
  };

  renderTableRecords = () => {
    return this.state.data[0].map((record) => {
      return (
        <TableRecord
          key={record.date + record.blueprintID + record.time + record.value}
          record={record}
        />
      );
    });
  };

  updateDateAndTimeMap = (key, value) => {
    this.dateAndTimeMap.set(key, value);
  };

  emptyDateOrTime = (map) => {
    if (
      map.get("startDate") === undefined ||
      map.get("startDate") === "" ||
      map.get("endDate") === undefined ||
      map.get("endDate") === "" ||
      map.get("startTime") === undefined ||
      map.get("startTime") === "" ||
      map.get("endTime") === undefined ||
      map.get("endTime") === ""
    ) {
      return true;
    }
    return false;
  };

  renderVariableSelectionButtons = () => {
    return this.state.blueprints.length > 0
      ? this.state.blueprints.map((blueprint) => {
          return (
            <button
              key={blueprint.storageName}
              id="table-alpha-button"
              className="btn btn-primary"
              style={{
                flexBasis: "20%",
                margin: "0px 5px 5px 5px",
                flexGrow: 1,
              }}
              type="button"
              value={blueprint.storageName}
              onClick={this.renderTableData}
            >
              {blueprint.storageName}
            </button>
          );
        })
      : "";
  };

  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div
            className="info-card full-opacity-info-card"
            style={{ marginBottom: "10px" }}
          >
            <div className="info-card-icon">
              <i className="fas fa-calendar-week fa-2x" aria-hidden="true" />
            </div>
            <div className="info-card-title">Daily Comparison</div>
            <div
              className="info-card-value"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              <div className="scrollable-table">
                {this.state.data === undefined ? (
                  <h1>{this.state.tableMessage}</h1>
                ) : (
                  ""
                )}
                <table
                  className="media-table table table-dark table-striped table-bordered table-sm"
                  cellSpacing="0"
                  style={{ marginTop: "10px" }}
                >
                  <thead>
                    <tr style={{ fontSize: "17.5px" }}>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">{this.state.labels[0]}</th>
                    </tr>
                  </thead>
                  <tbody id="tableRecords" style={{ fontSize: "15.5px" }}>
                    {this.state.data !== undefined ? (
                      this.renderTableRecords()
                    ) : (
                      <tr />
                    )}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexGrow: 1,
                  flexWrap: "wrap",
                }}
              >
                <DateTimePicker
                  target="table"
                  dateOrTimeMissing={this.state.missingDateOrTime}
                  updateDateAndTime={this.updateDateAndTimeMap}
                  openDirection={OPEN_UP}
                />
                {this.renderVariableSelectionButtons()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadingState: (loadingState) => {
      dispatch(setLoading(loadingState));
    },
  };
};

export default connect(null, mapDispatchToProps)(TablePanel);
