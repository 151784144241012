const suggestedMaxSecondary = 2;
const suggestedMinSecondary = -1;
const axisType = "linear";
const DoubleAxisChartOptionsWithLegends = {
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        id: "x-axis-time",
        display: true,
        position: "bottom"
      }
    ],
    yAxes: [
      {
        id: "y-axis-primary",
        display: true,
        type: axisType,
        position: "left"
      },
      {
        id: "y-axis-secondary",
        display: true,
        type: axisType,
        position: "right",
        ticks: {
          suggestedMax: suggestedMaxSecondary,
          suggestedMin: suggestedMinSecondary
        }
      }
    ]
  }
};
const DoubleAxisChartOptionsWithoutLegends = {
  legend: { display: false },
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        id: "x-axis-time",
        display: true,
        position: "bottom"
      }
    ],
    yAxes: [
      {
        id: "y-axis-primary",
        display: true,
        type: axisType,
        position: "left"
      },
      {
        id: "y-axis-secondary",
        display: true,
        type: axisType,
        position: "right",
        ticks: {
          suggestedMax: suggestedMaxSecondary,
          suggestedMin: suggestedMinSecondary
        }
      }
    ]
  }
};

const DefaultChartData = {
  labels: [
    "11:00",
    "11:10",
    "11:20",
    "11:30",
    "11:40",
    "11:50",
    "12:00",
    "12:10",
    "12:20",
    "12:30",
    "12:40",
    "12:50"
  ],
  datasets: [
    {
      label: " ",
      fill: false,
      data: [16, 52, 43, 21, 14, 20, 8, 11, 30, 36, 33, 11],
      backgroundColor: "#3f4045",
      borderColor: "#3f4045",
      pointHoverBorderColor: "#3f4045",
      pointHoverBackgroundColor: "#3f4045",
      borderWidth: 2,
      yAxisID: "y-axis-primary"
    },
    {
      label: "  ",
      fill: false,
      data: [12, 19, 3, 5, 12, 14, 31, 46, 84, 14, 32, 42],
      backgroundColor: "#eb9486",
      borderColor: "#eb9486",
      pointHoverBorderColor: "#eb9486",
      pointHoverBackgroundColor: "#eb9486",
      borderWidth: 2,
      yAxisID: "y-axis-primary"
    },
    {
      label: "   ",
      fill: false,
      data: [32, 45, 15, 8, 13, 26, 64, 11, 39, 15, 24, 17],
      backgroundColor: "#edb368",
      borderColor: "#edb368",
      pointHoverBorderColor: "#edb368",
      pointHoverBackgroundColor: "#edb368",
      borderWidth: 2,
      yAxisID: "y-axis-primary"
    }
  ]
};

export {
  DoubleAxisChartOptionsWithLegends,
  DoubleAxisChartOptionsWithoutLegends,
  DefaultChartData
};
