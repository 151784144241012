import React, { Component } from "react";
import { FormattedInput } from "@buttercup/react-formatted-input";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION,
} from "react-dates/lib/constants";
import "../../styles/scss/Common/custom-input.scss";
import {
  hoursMinutesTimeInputFormat,
  getDateStringFromDateObject,
  convertTimeFromLocalToGmt,
} from "../utils/date-time-utils";
import moment from "moment";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import { isMobile } from "react-device-detect";
// import Cacher from "../../core/cache/Cacher";
import {
  MEMORY_BLOCK_TYPE_DATES,
  MEMORY_BLOCK_TYPE_TIME,
} from "../../core/cache/MemoryBlockType";
// import MemoryBlock from "../../core/cache/MemoryBlock";
import { connect } from "react-redux";
import {
  setStartDate,
  setEndDate,
  setStartTime,
  setEndTime,
} from "../../actions/dateAndTimeActions";

class DateTimePicker extends Component {
  state = {
    startDate: undefined,
    endDate: undefined,
    focusedInput: undefined,
    startTime: undefined,
    endTime: undefined,
  };

  componentDidMount() {
    // this.cacher = new Cacher();
    // this.init();
  }

  init = () => {
    const dateMemoryBlockPayload = this.cacher.getMemoryBlock(
      MEMORY_BLOCK_TYPE_DATES
    );
    const timeMemoryBlockPayload = this.cacher.getMemoryBlock(
      MEMORY_BLOCK_TYPE_TIME
    );

    if (
      dateMemoryBlockPayload !== undefined &&
      dateMemoryBlockPayload.length > 1
    ) {
      this.setState({
        startDate: dateMemoryBlockPayload[0],
        endDate: dateMemoryBlockPayload[1],
      });
    }

    if (
      timeMemoryBlockPayload !== undefined &&
      timeMemoryBlockPayload.length > 1
    ) {
      this.setState({
        startTime: timeMemoryBlockPayload[0],
        endTime: timeMemoryBlockPayload[1],
      });
    }
  };

  updateDates = (startDate, endDate) => {
    if (startDate !== null && startDate !== undefined) {
      const startDatePayload = getDateStringFromDateObject(startDate);
      // const startDateMemoryBlock = new MemoryBlock(
      //   MEMORY_BLOCK_TYPE_DATES,
      //   startDatePayload
      // );
      // this.cacher.setMemoryBlockByIndex(startDateMemoryBlock, 0);

      this.props.updateDateAndTime("startDate", startDatePayload);
    }
    if (endDate !== null && endDate !== undefined) {
      this.props.updateDateAndTime(
        "endDate",
        getDateStringFromDateObject(endDate)
      );
    }
  };

  updateTime = (timeKey, value) => {
    this.setState({ startTime: value });
    this.props.updateDateAndTime("startTime", convertTimeFromLocalToGmt(value));
    this.cacher.updateMemoryBlock();
  };

  render() {
    return (
      <React.Fragment>
        {this.props.dateOrTimeMissing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexGrow: 1,
              flexWrap: "wrap",
              margin: "-5px 15px 15px 10px",
            }}
          >
            <div className="alert alert-warning" style={{ flexBasis: "100%" }}>
              <strong>Warning:</strong> Date/Time is missing!
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="dateAndTimePickerContainer">
          <DateRangePicker
            startDateId="startDate"
            endDateId="endDate"
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={({ startDate, endDate }) => {
              this.setState({ startDate, endDate });
              this.updateDates(startDate, endDate);
            }}
            displayFormat={"YYYY-MM-DD"}
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput) => {
              this.setState({ focusedInput });
            }}
            isOutsideRange={(day) =>
              isInclusivelyBeforeDay(day, moment("2019-04-30"))
            }
            enableOutsideDays={false}
            orientation={
              isMobile ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION
            }
            openDirection={this.props.openDirection}
          />
          {/* <DateRangePickerWrapper onDatesUpdate={this.updateDates} /> */}
          <FormattedInput
            className="form-control timeInputField"
            format={hoursMinutesTimeInputFormat}
            autoComplete="off"
            type="text"
            placeholder="Start Time [HH:MM]"
            onChange={(formattedValue, raw) => {
              this.setState({ startTime: formattedValue });
              this.props.updateDateAndTime(
                "startTime",
                convertTimeFromLocalToGmt(formattedValue)
              );
            }}
            value={this.state.startTime}
            ref={this.props.startTimeRef}
          />
          <FormattedInput
            className="form-control timeInputField"
            format={hoursMinutesTimeInputFormat}
            autoComplete="off"
            type="text"
            placeholder="End Time [HH:MM]"
            onChange={(formattedValue, raw) => {
              this.setState({ endTime: formattedValue });
              this.props.updateDateAndTime(
                "endTime",
                convertTimeFromLocalToGmt(formattedValue)
              );
            }}
            value={this.state.endTime}
            ref={this.props.endTimeRef}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dateAndTime: state.dateAndTime,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStartDate: (startDate) => {
      dispatch(setStartDate(startDate));
    },
    updateEndDate: (endDate) => {
      dispatch(setEndDate(endDate));
    },
    updateStartTime: (startTime) => {
      dispatch(setStartTime(startTime));
    },
    updateEndTime: (endTime) => {
      dispatch(setEndTime(endTime));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateTimePicker);
