import React, { Component } from "react";
import "../../styles/scss/Scheme/scheme.scss";
import { setLoading } from "../../actions/utilsActions";
import { connect } from "react-redux";
import {
  getSchemeRequest,
  addBlueprintRequest,
  deleteBlueprintRequest,
} from "../../api/schemeapi";
import { MESSAGE_SUCCESS } from "../../api/api-info";
import { validAgainstDuplicateBlueprint } from "../utils/blueprint-utils.js";
import SchemeForm from "./SchemeForm";
import { BlueprintsTable } from "./BlueprintsTable";

const ERROR_MESSAGES = {
  emptyFieldsError:
    "Please make sure to fill all the fields and choose an icon!",
  duplicateBlueprintError:
    "A blueprint already exists with the same Transfer Name or Storage Name!",
};

class SchemeBody extends Component {
  state = {
    blueprints: [],
    error: {
      active: false,
      message: "",
    },
  };

  componentDidMount() {
    this.getScheme();
  }

  getScheme = () => {
    this.props.loadingState(true);
    getSchemeRequest()
      .then((response) => {
        if (response.payload !== undefined) {
          this.setState({ blueprints: response.payload.scheme.blueprints });
        }
      })
      .finally(this.props.loadingState(false));
  };

  deleteBlueprint = (blueprint) => {
    this.props.loadingState(true);
    deleteBlueprintRequest(blueprint)
      .then((res) => {
        if (res.status === MESSAGE_SUCCESS) {
          this.removeBlueprintFromState(blueprint);
        }
      })
      .finally(this.props.loadingState(false));
  };

  removeBlueprintFromState = (blueprint) => {
    const tmpBlueprints = this.state.blueprints;
    const blueprintIndex = tmpBlueprints.indexOf(blueprint);
    tmpBlueprints.splice(blueprintIndex, 1);
    this.setState({
      blueprints: tmpBlueprints,
    });
  };

  addBlueprint = (newBlueprint) => {
    const { loadingState } = this.props;
    const { blueprints } = this.state;

    /* Validation */
    if (!newBlueprint.isValid()) {
      this.setState({
        error: {
          active: true,
          message: ERROR_MESSAGES.emptyFieldsError,
        },
      });
      return;
    } else if (!validAgainstDuplicateBlueprint(blueprints, newBlueprint)) {
      this.setState({
        error: {
          active: true,
          message: ERROR_MESSAGES.duplicateBlueprintError,
        },
      });
      return;
    }

    loadingState(true);
    addBlueprintRequest(newBlueprint.getSerializableObj())
      .then((res) => {
        if (res.status === MESSAGE_SUCCESS) {
          this.setState({
            blueprints: [...blueprints, newBlueprint],
          });
        }
        this.schemeFormRef.resetFields();
      })
      .finally(loadingState(false));
  };

  render() {
    const { error, blueprints } = this.state;
    return (
      <div className="scheme-control-container">
        <h2>Scheme Control</h2>
        <hr />
        {error.active && (
          <div className="alert alert-danger" role="alert">
            {error.message}
          </div>
        )}
        <SchemeForm
          blueprints={blueprints}
          onSubmit={this.addBlueprint}
          ref={(ref) => (this.schemeFormRef = ref)}
        />
        <hr />

        <BlueprintsTable
          blueprints={blueprints}
          onDelete={this.deleteBlueprint}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.user.username,
    token: state.user.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadingState: (loadingState) => {
      dispatch(setLoading(loadingState));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchemeBody);
