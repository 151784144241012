import React from "react";
import { FontAwesomeIcons } from "../../datasources/fa-icons";

const IconsGrid = ({ onSelectedIconChanged }) => {
  return Object.entries(FontAwesomeIcons).map(iconsCategory => {
    const categoryTitle = iconsCategory[0];
    const categoryTagsList = iconsCategory[1];
    return categoryTagsList.map((icon, index) => {
      const iconClass = icon + " icon-button";
      return (
        <div key={index} className="icon-group">
          <input
            id={categoryTitle + index}
            type="radio"
            name="icon-select"
            value={iconClass}
            onChange={e => onSelectedIconChanged(e)}
          />
          <label className={iconClass} htmlFor={categoryTitle + index} />
        </div>
      );
    });
  });
};

export default IconsGrid;
