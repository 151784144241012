import React from "react";

const MusicBody = () => {
  return (
    <React.Fragment>
      <div className="dashboard-content-pane">
        <div className="videoTitle" style={{ textAlign: "center" }}>
          CubHouTeCa
        </div>
        <div className="videoDescription" style={{ textAlign: "center" }}>
          Cubana House Techno Caribe On Bandcamp .. On Soundcloud .. On Beatport
          .. On Hulkshare .. On Traxsource ..{" "}
          <a href="“mailto:cubanahousemusic@gmail.com”">Contact Us</a> <br />
        </div>

        <iframe
          title="track-1"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://bandcamp.com/EmbeddedPlayer/album=1373625707/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=803305585/transparent=true/"
          seamless=""
        >
          <a href="http://cubanahouse-technocariberecordins.bandcamp.com/album/bpm">
            bpm by Enmanuel Yasell
          </a>
        </iframe>
        <iframe
          title="track-2"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/304909625&amp;color=%23ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true&amp;visual=true"
        />
        <iframe
          title="track-3"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://embed.beatport.com/?id=6902694&amp;type=track"
        />
        <iframe
          title="track-4"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://embed.traxsource.com/player/track/4141534"
          __idm_id__="52297730"
        />
        <iframe
          title="track-5"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://embed.beatport.com/?id=5794517&amp;type=track"
        />
        <iframe
          title="track-6"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://bandcamp.com/EmbeddedPlayer/album=1503328447/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/track=3756205114/transparent=true/"
          seamless=""
        >
          <a href="http://cubanahouse-technocariberecordins.bandcamp.com/album/teknobrat-product-of-lassitude">
            Teknobrat - Product of Lassitude by Robin Kampschoer Rework
          </a>
        </iframe>
        <iframe
          title="track-7"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://embed.traxsource.com/player/track/2687617"
          __idm_id__="52297729"
        />
        <iframe
          title="track-8"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="http://www.hulkshare.com/embed.php?fn=3vh11ipgv874"
        />
        <iframe
          title="track-9"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="http://www.hulkshare.com/embed.php?fn=hwnboinw9j40"
        />
        <iframe
          title="track-10"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="http://www.hulkshare.com/embed.php?fn=wljvn5diyvwg"
        />
        <iframe
          title="track-11"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/161904009&amp;color=%23ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true&amp;visual=true"
        />
        <iframe
          title="track-12"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://embed.traxsource.com/player/track/2967609"
          __idm_id__="52297731"
        />
        <iframe
          title="track-13"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCubanaHouseTechnoCaribe%2Fposts%2F713913132014077&amp;width=500"
        />
        <iframe
          title="track-14"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCubanaHouseTechnoCaribe%2Fposts%2F966762336729154&amp;width=500"
        />
        <iframe
          title="track-15"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCubanaHouseTechnoCaribe%2Fposts%2F1440445462694170&amp;width=500"
        />
        <iframe
          title="track-16"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCubanaHouseTechnoCaribe%2Fposts%2F889954464409942&amp;width=500"
        />
        <iframe
          title="track-17"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCubanaHouseTechnoCaribe%2Fposts%2F728528507219206&amp;width=500"
        />
        <iframe
          title="track-18"
          style={{ border: "0", width: "260px", height: "250px" }}
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FCubanaHouseTechnoCaribe%2Fposts%2F665572080181516&amp;width=500"
        />
      </div>
    </React.Fragment>
  );
};

export default MusicBody;
