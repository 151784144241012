import React, { Component } from "react";
import { FormattedInput } from "@buttercup/react-formatted-input";
import { hoursMinutesSecondsTimeInputFormat } from "../utils/date-time-utils";
import BlueprintTypes from "./BlueprintTypesList";
import {
  BLUEPRINT_TYPES,
  getFieldValuesArray,
  getFieldInfoArray,
  BLUEPRINT_TYPE_CATEGORIES,
} from "./BlueprintTypes.js";
import BlueprintObj from "./BlueprintObj";
import TypeSpecField from "./TypeSpecField";
import { INPUT_TYPES } from "./PolyInput/PolyInputConstants";
import IconsGridWrapper from "./IconsGridWrapper";

class SchemeForm extends Component {
  state = {
    selectedType: BLUEPRINT_TYPES[0],
    selectedIcon: undefined,
    transferName: "",
    frequency: "",
    channel: "",
    staticFields: [],
    dynamicFieldsInfo: getFieldInfoArray(BLUEPRINT_TYPES[0]),
    dynamicFieldsValues: getFieldValuesArray(BLUEPRINT_TYPES[0]),
  };
  dynamicFieldRefs = [];

  onSelectedIconChange = (icon) => {
    this.setState({
      selectedIcon: icon,
    });
  };

  onSelectedTypeChanged = (type) => {
    this.setState({
      selectedType: type,
      dynamicFieldsValues: getFieldValuesArray(type),
      dynamicFieldsInfo: getFieldInfoArray(type),
    });

    if (type.category === BLUEPRINT_TYPE_CATEGORIES.ACTION) {
      this.setState({ frequency: "-:-:-" });
    }

    this.dynamicFieldRefs = new Array(this.dynamicFieldRefs);
  };

  resetFields = () => {
    this.setState({
      selectedIcon: undefined,
      error: {
        active: false,
        message: "",
      },
      frequency: "",
      channel: "",
      dynamicFieldsValues: getFieldValuesArray(this.state.selectedType),
    });

    this.transferNameInput.value = "";
    this.storageNameInput.value = "";

    this.iconsGridWrapper.reset();

    this.dynamicFieldRefs.forEach((field) => {
      field.resetField();
    });
  };

  onAddBlueprint = (e) => {
    e.preventDefault();
    const { selectedType, frequency, selectedIcon } = this.state;

    const booleanMandatory = Boolean(this.mandatoryInput.value);

    const submittedBlueprint = new BlueprintObj(
      selectedType,
      this.transferNameInput.value,
      this.storageNameInput.value,
      frequency,
      booleanMandatory,
      selectedIcon,
      this.state.dynamicFieldsValues
    );
    this.props.onSubmit(submittedBlueprint);
  };

  onFieldUpdateHandler = (index, value) => {
    const tmpFields = this.state.dynamicFieldsValues;
    tmpFields[index] = value;
    this.setState({ dynamicFields: tmpFields });
  };

  renderTypeSpecFields = () => {
    const type = this.state.selectedType;
    const { blueprints } = this.props;
    return type.fields.map((field, index) => {
      const {
        fieldName: name,
        placeholder,
        maxLength,
        inputType: type,
        customComponent: CustomComponent,
      } = field;
      const htmlNameAttribute = name.trim().replace(/ /g, "-").toLowerCase();
      if (type === INPUT_TYPES.CUSTOM) {
        return (
          <CustomComponent
            index={index}
            key={name}
            htmlName={htmlNameAttribute}
            name={name}
            placeholder={placeholder}
            fieldType={type}
            onFieldUpdate={this.onFieldUpdateHandler}
            blueprints={blueprints}
            ref={(ref) => {
              this.dynamicFieldRefs[index] = ref;
            }}
          />
        );
      }
      return (
        <TypeSpecField
          index={index}
          key={name}
          htmlName={htmlNameAttribute}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          fieldType={type}
          onFieldUpdate={this.onFieldUpdateHandler}
          value={this.state.dynamicFieldsValues[index]}
          ref={(ref) => {
            this.dynamicFieldRefs[index] = ref;
          }}
        />
      );
    });
  };

  render() {
    const { category } = this.state.selectedType;
    const isSensor = category === BLUEPRINT_TYPE_CATEGORIES.SENSOR;
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="blueprintTypes">Type:</label>
          <div
            id="blueprint-types-container"
            className="form-control"
            name="blueprintTypes"
          >
            <BlueprintTypes
              onSelectedTypeChanged={this.onSelectedTypeChanged}
            />
          </div>
        </div>

        <div className="form-group col">
          <label htmlFor="transferName">Transfer Name</label>
          <input
            className="form-control"
            name="transferName"
            type="text"
            placeholder="BLUEPRINT_NAME"
            ref={(ref) => {
              this.transferNameInput = ref;
            }}
          />
        </div>

        <div className="form-group col">
          <label htmlFor="storageName">Display Name</label>
          <input
            className="form-control"
            name="storageName"
            type="text"
            placeholder="Blueprint Name"
            ref={(ref) => (this.storageNameInput = ref)}
          />
        </div>

        {isSensor && (
          <div className="form-group col">
            <label htmlFor="frequency">Frequency</label>
            <FormattedInput
              className="form-control"
              format={hoursMinutesSecondsTimeInputFormat}
              autoComplete="off"
              type="text"
              placeholder={"Hours:Minutes:Seconds"}
              value={this.state.frequency}
              disabled={!isSensor}
              onChange={(formattedValue) => {
                this.setState({ frequency: formattedValue });
              }}
            />
          </div>
        )}

        <div className="form-group col">
          <label htmlFor="mandatory">Mandatory</label>
          <select
            className="form-control"
            name="mandatory"
            type="text"
            ref={(ref) => (this.mandatoryInput = ref)}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>

        {this.renderTypeSpecFields()}

        <IconsGridWrapper
          ref={(ref) => (this.iconsGridWrapper = ref)}
          onSelectedIconChange={this.onSelectedIconChange}
        />

        <button
          className="btn btn-info"
          name="addInspection"
          onClick={(e) => this.onAddBlueprint(e)}
        >
          Add a new blueprint
        </button>
      </form>
    );
  }
}

export default SchemeForm;
