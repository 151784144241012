import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer className="row home-footer">
        <div className="col-lg-10 col-md-12">
          Copyright&copy; <i>Earth Station Modular</i> - There is no difference
          between the peasantry and the castle - Franz Kafka, The Castle
        </div>
        <div className="col-lg-2 col-md-12">
          <a href="##">
            <i
              className="fab fa-facebook fa-2x facebook social-media-icon"
              aria-hidden="true"
            />
          </a>
          <a href="##">
            <i
              className="fab fa-twitter-square fa-2x social-media-icon"
              aria-hidden="true"
            />
          </a>
          <a href="##">
            <i
              className="fab fa-instagram fa-2x social-media-icon"
              aria-hidden="true"
            />
          </a>
          <a href="##">
            <i
              className="fab fa-linkedin fa-2x social-media-icon"
              aria-hidden="true"
            />
          </a>
        </div>
      </footer>
    );
  }
}

export default Footer;
