import { ADD_TASK, EDIT_TASK, REMOVE_TASK, LOAD_TASKS } from "../actions/types";

const initialState = {
  tasks: [],
};

export default function (state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case LOAD_TASKS:
      return { tasks: payload };
    case ADD_TASK:
      return { tasks: [...state.tasks, payload] };
    case EDIT_TASK: {
      const taskIndex = getTaskByID(state, action)[1];
      const updatedTasks = state.tasks.map((task, index) =>
        index === taskIndex ? payload : task
      );
      return { tasks: [...updatedTasks] };
    }
    case REMOVE_TASK: {
      const { payload: id } = action;
      const updatedTasks = state.tasks.slice().filter((task) => task.id !== id);
      return { tasks: [...updatedTasks] };
    }
    default:
      return state;
  }
}

/**
 * @private
 */
const getTaskByID = (state, { payload }) => {
  const targetTaskIndex = state.tasks.findIndex(
    (task) => task.id === payload.id
  );
  return [state.tasks[targetTaskIndex], targetTaskIndex];
};
