const routePrefix = "/ucp";
const UserPages = {
  pages: [
    {
      header: {
        enabled: true,
        index: "U1",
        title: "Analytics",
        icon: "fa-chart-bar",
        routeName: "analytics",
        route: routePrefix + "/analytics",
      },
      body: { valid: false },
    },
    {
      header: {
        enabled: true,
        index: "U2",
        title: "Scheme",
        icon: "fa-drafting-compass",
        routeName: "scheme",
        route: routePrefix + "/scheme",
      },
      body: { valid: false },
    },
    {
      header: {
        enabled: true,
        index: "U3",
        title: "Tasks",
        icon: "fa-tasks",
        routeName: "tasks",
        route: routePrefix + "/tasks",
      },
      body: { valid: false },
    },
    {
      header: {
        enabled: false,
        index: "U4",
        title: "Media",
        icon: "fa-play",
        routeName: "media",
        route: routePrefix + "/media",
      },
      body: { valid: false },
    },
  ],
};

export default UserPages;
