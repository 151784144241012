import React, { Component } from "react";
import { DATA_INDICES } from "./TriggerWrapper";

class ChronicalTrigger extends Component {
  getInterpretableObj = ([hours, minutes, seconds]) => {
    const composedObj = {};

    if (hours.length > 0) {
      composedObj.hours = hours;
    }

    if (minutes.length > 0) {
      composedObj.minutes = minutes;
    }

    if (seconds.length > 0) {
      composedObj.seconds = seconds;
    }

    if (Object.entries(composedObj).length < 1) {
      return null;
    }

    return composedObj;
  };

  onChangeHandler = ({ target: { value } }, changeEvent) => {
    //instantlyUpdatedState - Shadow copy used to instantely update the interpreter
    const instantlyUpdatedState = [...this.props.data];
    const { onChange } = this.props;
    const {
      CHRONICAL: { HOURS, MINUTES, SECONDS },
    } = DATA_INDICES;
    switch (changeEvent) {
      case CHANGE_EVENTS.HOURS:
        onChange(HOURS, value);
        instantlyUpdatedState[HOURS] = value;
        break;
      case CHANGE_EVENTS.MINUTES:
        onChange(MINUTES, value);
        instantlyUpdatedState[MINUTES] = value;
        break;
      case CHANGE_EVENTS.SECONDS:
        onChange(SECONDS, value);
        instantlyUpdatedState[SECONDS] = value;
        break;
      default:
        return;
    }
    this.props.onInterpret(this.getInterpretableObj(instantlyUpdatedState));
  };

  render() {
    const [hours, minutes, seconds] = this.props.data;
    return (
      <>
        <div className="trigger-type-container">
          <i className="fas fa-history"></i>
        </div>
        <span className="hours-container">
          <input
            required
            type="text"
            className="form-control"
            placeholder="Hours"
            value={hours}
            onChange={(e) => this.onChangeHandler(e, CHANGE_EVENTS.HOURS)}
          />
        </span>
        <span className="minutes-container">
          <input
            required
            type="text"
            className="form-control"
            placeholder="Minutes"
            value={minutes}
            onChange={(e) => this.onChangeHandler(e, CHANGE_EVENTS.MINUTES)}
          />
        </span>
        <span className="seconds-container">
          <input
            required
            type="text"
            className="form-control"
            placeholder="Seconds"
            value={seconds}
            onChange={(e) => this.onChangeHandler(e, CHANGE_EVENTS.SECONDS)}
          />
        </span>
      </>
    );
  }
}

const CHANGE_EVENTS = {
  HOURS: "hrs",
  MINUTES: "mns",
  SECONDS: "scs",
};

export default ChronicalTrigger;
