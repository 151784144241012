import { INPUT_TYPES } from "../../../Scheme/PolyInput/PolyInputConstants";

const OPERATORS = [
  {
    name: "GREATER THAN",
    symbolicName: ">",
    targetType: INPUT_TYPES.NUMBER,
    // operation: greaterThanOp,
    interpretation: "is above",
  },
  {
    name: "LESS THAN",
    symbolicName: "<",
    targetType: INPUT_TYPES.NUMBER,
    // operation: lessThanOp,
    interpretation: "is below",
  },
  {
    name: "EQUALS",
    symbolicName: "=",
    targetType: INPUT_TYPES.NUMBER,
    // operation: equalsOp,
    interpretation: "equals",
  },
  {
    name: "CONTAINS",
    // symbolicName: "CONTAINS",
    symbolicName: "⊂",
    targetType: INPUT_TYPES.TEXT,
    // operation: containsOp,
    interpretation: "contains the text",
  },
  //   {
  //     name: "ON",
  //     symbolicName: "ON/1",
  //     targetType: INPUT_TYPES.NUMBER,
  //     // operation: getOnOp,
  //     interpretation: {},
  //   },
  //   {
  //     name: "OFF",
  //     symbolicName: "OFF/0",
  //     targetType: INPUT_TYPES.NUMBER,
  //     // operation: getOffOp,
  //     interpretation: {},
  //   },
];

// const greaterThanOp = (target, value) => {
//   return target > value;
// };

export default OPERATORS;
