import { logError } from "../logger/Logger";

class Observer {
  setHandlerFunction(handlerFunction) {
    if (typeof handlerFunction !== "function") {
      logError(this, "Invalid handler function");
    } else {
      this.handlerFunction = handlerFunction;
    }
  }

  callHandlerFunction(operation, payload, serializeOperations) {
    if (this.handlerFunction === undefined) {
      logError(this, "No handler function was provided at construction");
      return;
    }
    this.handlerFunction(operation, payload, serializeOperations);
  }
}

export default Observer;
