import React, { Component } from "react";
import PropTypes from "prop-types";
import IconsGrid from "./IconsGrid";
import "../../styles/scss/Common/icons-grid.scss";

class IconsGridWrapper extends Component {
  state = { selectedIcon: "" };

  onSelectedIconChange = (e) => {
    this.selectedIconRef = e.target;
    const selectedIconValue = e.target.value;

    this.setState({
      selectedIcon: selectedIconValue,
    });

    const containerOnSelectedIconChange = this.props.onSelectedIconChange;
    containerOnSelectedIconChange !== undefined &&
      containerOnSelectedIconChange(selectedIconValue);
  };

  reset = () => {
    this.selectedIconRef.checked = false;
  };

  render() {
    return (
      <div className="form-group icons-dictionary">
        <label htmlFor="icon">Icon</label>
        <div id="icon-select" className="form-control" name="icon">
          <IconsGrid onSelectedIconChanged={this.onSelectedIconChange} />
        </div>
      </div>
    );
  }
}

IconsGridWrapper.propTypes = {
  onSelectedIconChange: PropTypes.func.isRequired,
};

export default IconsGridWrapper;
