import axios from "axios";
import {
  ADD_BLUEPRINT_ENDPOINT,
  DELETE_BLUEPRINT_ENDPOINT,
  GET_SCHEME_ENDPOINT,
  JSON_HEADER_NOCACHE,
  MESSAGE_SUCCESS,
  MESSAGE_FAILURE,
  MESSAGE_ERROR,
  JSON_HEADER_CACHABLE,
} from "./api-info";

export const getSchemeRequest = () => {
  return axios
    .get(GET_SCHEME_ENDPOINT, JSON_HEADER_NOCACHE)
    .then((res) => {
      return {
        status: MESSAGE_SUCCESS,
        payload: {
          scheme: res.data,
        },
      };
    })
    .catch((e) => {
      return { status: MESSAGE_FAILURE, errorMessage: e };
    });
};

export const addBlueprintRequest = (blueprint) => {
  const payload = JSON.stringify(blueprint);
  return axios
    .post(ADD_BLUEPRINT_ENDPOINT, payload, JSON_HEADER_NOCACHE)
    .then((res) => {
      if (res.data.status === "SUCCESS") {
        return { status: MESSAGE_SUCCESS };
      } else {
        return { status: MESSAGE_FAILURE };
      }
    })
    .catch((e) => {
      return { status: MESSAGE_ERROR, errorMessage: e };
    });
};

export const deleteBlueprintRequest = (blueprint) => {
  const payload = JSON.stringify(blueprint);
  return axios
    .post(DELETE_BLUEPRINT_ENDPOINT, payload, JSON_HEADER_CACHABLE)
    .then((res) => {
      if (res.data.status === "SUCCESS") {
        return { status: MESSAGE_SUCCESS };
      } else {
        return { status: MESSAGE_FAILURE };
      }
    })
    .catch((e) => {
      return { status: MESSAGE_ERROR, errorMessage: e };
    });
};
