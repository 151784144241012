import React, { Component } from "react";
import { connect } from "react-redux";
import { userLogout } from "../../actions/userActions";
import { extractUsernameFromEmail } from "../utils/string-utils";
import DataBar from "./DataBar";

class Header extends Component {
  state = {};
  loggedIn = this.props.loggedIn;

  render() {
    return (
      <div className="row dashboard-header">
        {this.loggedIn ? (
          <div className="control-bar col-lg-10 col-sm-12">
            {/* <SearchBar className="search-bar" /> */}
            <DataBar className="data-bar" />
          </div>
        ) : (
          ""
        )}
        <div className="user-info col-lg-2 col-sm-12 col-md-12">
          <div className="username">
            {this.loggedIn && this.props.username !== undefined
              ? extractUsernameFromEmail(this.props.username)
              : "Guest"}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { username: state.user.username, loggedIn: state.user.active };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(userLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
