import React, { Component } from "react";
import ChartPanel from "./ChartComponents/ChartPanel";
import TablePanel from "./TableComponents/TablePanel";
import { getLatestRecordsRequest } from "../../api/recordapi";
import { MESSAGE_SUCCESS } from "../../api/api-info";
import { recordToRecordDTO } from "../utils/record-utils";
import Record from "./Record";
import { SHA256 } from "crypto-js";
import SectionTitle from "../Common/SectionTitle";

class AnalyticsBody extends Component {
  state = { blueprints: [], data: [] };

  periodicRecordsUpdate;

  componentDidMount() {
    this.getRecords();
    this.periodicRecordsUpdate = setInterval(() => {
      this.getRecords();
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.periodicRecordsUpdate);
  }

  getRecords = () => {
    const latestRecordRequest = getLatestRecordsRequest();
    latestRecordRequest.then((res) => {
      if (res.status === MESSAGE_SUCCESS) {
        this.setState({
          blueprints: res.payload.blueprints,
          data: res.payload.data,
        });
      }
    });
  };

  renderRecords = () => {
    const blueprints = this.state.blueprints;
    const records = this.state.data;
    return records.length > 0
      ? records.map((record) => {
          const recordDTO = recordToRecordDTO(
            record,
            blueprints[record.blueprintID]
          );
          return (
            <Record
              key={SHA256(
                record.blueprintID + record.value + record.date + record.time
              )}
              record={recordDTO}
            />
          );
        })
      : "";
  };

  render() {
    return (
      <React.Fragment>
        {/* <div className="records-pane row">{this.renderRecords()}</div> */}
        <SectionTitle title={"Analytics"} />
        <ChartPanel />
        <TablePanel />
      </React.Fragment>
    );
  }
}

export default AnalyticsBody;
