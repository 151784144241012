import moment from "moment";

export const MONTH_TO_NUMBER = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11
};

export const hoursMinutesTimeInputFormat = [
  { char: /[0-2]/ },
  { char: /[0-9]/ },
  { exactly: ":" },
  { char: /[0-5]/ },
  { char: /[0-9]/ }
];

export const hoursMinutesSecondsTimeInputFormat = [
  { char: /\d/, repeat: 2 },
  { exactly: ":" },
  { char: /\d/, repeat: 2 },
  { exactly: ":" },
  { char: /\d/, repeat: 2 }
];

export const fourDigitInputFormat = [{ char: /\d/, repeat: 4 }];

export const getDateStringFromDateObject = date => {
  const isoDate = date._d.toISOString();
  const dateTimeSeperatorIndex = isoDate.indexOf("T"); //2011-10-05T14:48:00.000Z Format example
  const processedDate = isoDate.slice(0, dateTimeSeperatorIndex);
  return moment(processedDate, "YYYY-MM-DD").format("YYYY-MM-DD");
};

export const convertTimeFromGmtToLocal = time => {
  return moment(time, "HH:mm:ss")
    .add(moment().utcOffset(), "minutes")
    .format("HH:mm:ss");
};

export const convertTimeFromLocalToGmt = time => {
  if (time.length > 3) {
    const hours = time.slice(0, 2);
    const hoursInInteger = parseInt(hours);
    // Divded by 60 as the difference is in minutes.
    const differenceToGmt = hoursInInteger - moment().utcOffset() / 60;
    if (differenceToGmt < 0) {
      return moment(time, "HH:mm").format("HH:mm");
    } else {
      return moment(time, "HH:mm")
        .subtract(moment().utcOffset(), "minutes")
        .format("HH:mm");
    }
  }
  return time;
};

export const getRemainingPunishmentTime = punishmentTime => {
  /* Divide the returned value by 1000 to get it in seconds */
  if (punishmentTime === "-") return -1;

  const timeDifference = Math.floor(moment().diff(punishmentTime) / 1000);
  return timeDifference;
};
