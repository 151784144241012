import { ADD_INSPECTION, REMOVE_INSPECTION } from "../actions/types";

const initialState = {
  entries: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_INSPECTION:
      let tempArray = [...state.entries];
      tempArray.unshift(action.payload);
      return {
        ...state,
        entries: tempArray
      };
    case REMOVE_INSPECTION:
      let filteredEntries = state.entries.filter(
        entry => entry.id !== action.payload
      );
      return {
        ...state,
        entries: filteredEntries
      };
    default:
      return state;
  }
}
