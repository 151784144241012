import React, { Component } from "react";
import { TRIGGER_TYPES } from "./TriggerConstants";
import ChronicalTrigger from "./ChronicalTrigger";
import ConditionalTrigger from "./ConditionalTrigger";
import PropTypes from "prop-types";
import SensorsContext from "../../../../contexts/sensors-context";

class TriggerWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      data: this.initData(props),
    };
  }

  initData = ({ type, initialData }) => {
    const emptyArrayTemplate =
      type === TRIGGER_TYPES.CONDITIONAL
        ? DATA_ARRAY_STRUCTURES.CONDITIONAL
        : DATA_ARRAY_STRUCTURES.CHRONICAL;
    const dataInArray = initialData && Object.values(initialData);
    if (type === TRIGGER_TYPES.CONDITIONAL) {
      return initialData
        ? [[{ blueprint: dataInArray[0] }], [dataInArray[1]], dataInArray[2]]
        : emptyArrayTemplate;
    }
    return initialData ? dataInArray : emptyArrayTemplate;
  };

  componentDidMount() {
    this.setState({ mounted: true });
  }

  onInterpret = (interpretableObj) => {
    const { index } = this.props;
    if (interpretableObj !== null) {
      this.props.onInjectCompiledData(index, interpretableObj);
    }
  };

  onChange = (fieldIndex, value) => {
    const tmpData = [...this.state.data];
    tmpData[fieldIndex] = value;
    this.setState({ data: tmpData });
  };

  render() {
    const { type, onDelete, index, ZIndex } = this.props;
    const { data } = this.state;
    return (
      <li
        type={type}
        style={{ zIndex: ZIndex }}
        className={`${this.state.mounted && "trigger-mounted"}`}
      >
        <div className="trigger-container">
          <TriggerBody
            type={type}
            data={data}
            onChangeHandler={this.onChange}
            onInterpretHandler={this.onInterpret}
            triggerOptions={this.context}
          />
          <TriggerDeleteButton index={index} onDeleteHandler={onDelete} />
        </div>
      </li>
    );
  }
}

const TriggerBody = ({
  type: triggerType,
  data,
  onInterpretHandler,
  onChangeHandler,
  triggerOptions,
}) => {
  switch (triggerType) {
    case TRIGGER_TYPES.CHRONICAL:
      return (
        <ChronicalTrigger
          data={data}
          onChange={onChangeHandler}
          onInterpret={onInterpretHandler}
        />
      );
    case TRIGGER_TYPES.CONDITIONAL:
      return (
        <ConditionalTrigger
          data={data}
          onChange={onChangeHandler}
          onInterpret={onInterpretHandler}
          triggerOptions={triggerOptions}
        />
      );
    default:
      return "";
  }
};

const TriggerDeleteButton = ({ index, onDeleteHandler }) => {
  return (
    <div
      className="delete-trigger-container"
      onClick={() => onDeleteHandler(index)}
    >
      <i className="far fa-trash-alt"></i>
    </div>
  );
};

TriggerWrapper.propTypes = {
  type: PropTypes.string.isRequired,
};

/**
 * data[] structure:-
 * Conditional: [0] -> Blueprint, [1] -> Condition, [2] -> Trigger Value
 * Chronical: [0] -> Hours, [1] -> Minutes, [2] -> Seconds
 */
const DATA_ARRAY_STRUCTURES = {
  CHRONICAL: ["", "", ""],
  CONDITIONAL: [[], [], ""],
};

export const DATA_INDICES = {
  CHRONICAL: { HOURS: 0, MINUTES: 1, SECONDS: 2 },
  CONDITIONAL: { BLUEPRINT: 0, CONDITION: 1, TRIGGER_VALUE: 2 },
};

TriggerWrapper.contextType = SensorsContext;

export default TriggerWrapper;
