import { validAgainstEmptyFields } from "../utils/blueprint-utils.js";
import { BLUEPRINT_TYPES } from "./BlueprintTypes.js";

class BlueprintObj {
  constructor(
    type,
    transferName,
    storageName,
    frequency,
    mandatory,
    icon,
    extensionFields = []
  ) {
    this.type = type;
    this.rawTransferName = transferName;
    this.storageName = storageName;
    this.frequency = frequency;
    this.mandatory = mandatory;
    this.icon = icon;
    this.extensionFields = extensionFields;
    this.transferName = this.generateStandardizedTransferName(
      type,
      transferName,
      extensionFields
    );
  }

  isValid = () => validAgainstEmptyFields(this);

  getSerializableObj = () => {
    return {
      transferName: this.transferName,
      storageName: this.storageName,
      mandatory: this.mandatory,
      frequency: this.frequency,
      icon: this.icon,
    };
  };

  generateStandardizedTransferName = (
    type,
    rawTransferName,
    extensionFields
  ) => {
    const formattedRawTransferName = rawTransferName
      .toUpperCase()
      .trim()
      .replace(/ /g, "_");
    const isGeneric = type.name === BLUEPRINT_TYPES[0].name;
    const tmpName = `${type.name}-${formattedRawTransferName}-`.replace(
      / /g,
      "_"
    );
    let extensionNameChain = "";
    extensionFields.forEach((value, index) => {
      if (index !== this.extensionFields.length - 1) {
        extensionNameChain += `${value}-`;
      } else {
        extensionNameChain += `${value}`;
      }
    });
    return !isGeneric ? tmpName + extensionNameChain : formattedRawTransferName;
  };
}

export default BlueprintObj;
