import {
  SET_START_DATE,
  SET_END_DATE,
  SET_START_TIME,
  SET_END_TIME
} from "../actions/types";

const initialState = {
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_START_DATE:
      return { ...state, startDate: action.payload };
    case SET_END_DATE:
      return { ...state, endDate: action.payload };
    case SET_START_TIME:
      return { ...state, startTime: action.payload };
    case SET_END_TIME:
      return { ...state, endTime: action.payload };
    default:
      return state;
  }
}
