import Observer from "../observers/Observer";
import { logError, logWarning } from "../logger/Logger";
import Serializer from "../serializer/Serializer";

class LocalStorageManager extends Observer {
  constructor() {
    super();
    this.setHandlerFunction(this.updateLocalStorage);
  }

  updateLocalStorage(title, payload, serializeOperations) {
    if (this.validateWriteObservation(title, payload)) {
      const serializedPayload = Serializer(serializeOperations, payload);
      const stringifyedPayload = JSON.stringify(serializedPayload);
      localStorage.setItem(title, stringifyedPayload);
    }
  }

  getFromLocalStorage(title, deserializeOps) {
    if (this.validateReadObservation(title)) {
      const stringifyedPayload = localStorage.getItem(title);
      if (stringifyedPayload === null || stringifyedPayload === undefined) {
        logWarning(this, "No storage element found");
        return undefined;
      }
      const parsedPayload = JSON.parse(stringifyedPayload);
      const unserializedPayload = Serializer(deserializeOps, parsedPayload);
      return unserializedPayload;
    }
  }

  validateReadObservation(title) {
    if (title.length >= 1 && typeof title === "string") {
      return true;
    }
    logError(this, "Invalid observation");
    return false;
  }

  validateWriteObservation(title, payload) {
    if (
      title.length >= 2 &&
      typeof title === "string" &&
      payload !== undefined
    ) {
      return true;
    }
    logError(this, "Invalid observation");
    return false;
  }
}

export default LocalStorageManager;
