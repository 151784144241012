import axios from "axios";
import {
  JSON_HEADER_NOCACHE,
  LOAD_TASKS_ENDPOINT,
  DELETE_TASK_ENDPOINT,
  ADD_TASK_ENDPOINT,
  EDIT_TASK_ENDPOINT,
  MESSAGE_SUCCESS,
} from "./api-info";
import { BLUEPRINT_TYPE_CATEGORIES } from "../components/Scheme/BlueprintTypes";
import { v4 as uuid } from "uuid";
import CONDITION_OPERATORS from "../components/Tasks/AddTask/Triggers/ConditionOperators";
import { TRIGGER_TYPES } from "../components/Tasks/AddTask/Triggers/TriggerConstants";
import interpret, {
  INTERPRETATION_MODES,
} from "../components/Tasks/Interpreter";
import Store from "../store";
import {
  addAutomatedTask,
  editAutomatedTask,
  loadAutomatedTasks,
  removeAutomatedTask,
} from "../actions/automatedTasksActions";
import { setLoading } from "../actions/utilsActions";

export const loadTasks = () => {
  Store.dispatch(setLoading(true));
  return axios
    .get(LOAD_TASKS_ENDPOINT, {}, JSON_HEADER_NOCACHE)
    .then((res) => res.data)
    .then(({ content: tasks }) =>
      tasks.map((task) => formatBackendObjToFrontend(task))
    )
    .then((content) => Store.dispatch(loadAutomatedTasks(content)))
    .catch((e) => e)
    .finally(() => Store.dispatch(setLoading(false)))
};

export const addTask = (task) => {
  const formattedTask = formatFrontendToBackend(task);
  const payload = JSON.stringify(formattedTask);
  return axios
    .post(ADD_TASK_ENDPOINT, payload, JSON_HEADER_NOCACHE)
    .then((res) => res.data)
	.then((data) => {
		const success = data.status !== undefined && data.status === MESSAGE_SUCCESS;
		if(success){
			Store.dispatch(addAutomatedTask(task));
		}
	})
    .catch((e) => console.log(e));
};

export const editTask = (task) => {
  const formattedTask = formatFrontendToBackend(task);
  const payload = JSON.stringify(formattedTask);
  return axios
    .post(EDIT_TASK_ENDPOINT, payload, JSON_HEADER_NOCACHE)
    .then((res) => res.data)
	.then((data) => {
		const success = data.status !== undefined && data.status === MESSAGE_SUCCESS;
		if(success){
			Store.dispatch(editAutomatedTask(task));
		}
	})
    .catch((e) => console.log(e));
};

export const deleteTask = (taskID) => {
  Store.dispatch(setLoading(true));
  return axios
    .post(DELETE_TASK_ENDPOINT + taskID, JSON_HEADER_NOCACHE)
    .then((res) => res.data)
    .then(({ status }) =>
      status === MESSAGE_SUCCESS
        ? Store.dispatch(removeAutomatedTask(taskID))
        : loadTasks()
    )
    .catch((e) => console.log(e))
    .finally(() => Store.dispatch(setLoading(false)))
};

export const formatFrontendToBackend = ({ title, icon, triggers, actions }) => {
  let formattedTriggers = triggers.filter(({ type }) => {
    return type !== TRIGGER_TYPES.CHRONICAL;
  });
  if (formattedTriggers.length > 0) {
    formattedTriggers = formattedTriggers.map(
      ({ data: { blueprint, trigger, condition } }) => ({
        sourceBlueprint: blueprint,
        conditionOP: condition.symbolicName,
        thresholdValue: trigger,
      })
    );
  }

  const formattedActions = actions.map((action) => action.blueprint);
  const formattedObj = {
    name: title,
    icon: icon,
    triggers: [...formattedTriggers],
    actions: [...formattedActions],
  };

  if (formattedTriggers.length < triggers.length) {
    const chronicalTrigger = triggers.find(
      ({ type }) => type === TRIGGER_TYPES.CHRONICAL
    );
    const triggerData = Object.entries(chronicalTrigger.data);
    triggerData.forEach(([propertyName, value]) => {
      formattedObj[propertyName] = value;
    });
  }

  return formattedObj;
};

export const formatBackendObjToFrontend = ({
  id,
  name,
  icon,
  triggers,
  actions,
  hours,
  minutes,
  seconds,
}) => {
  const formattedActions = actions.map((blueprint) => ({
    id: blueprint.id,
    category: BLUEPRINT_TYPE_CATEGORIES.ACTION,
    blueprint: blueprint,
  }));

  const formattedTriggers = triggers.map(
    ({ sourceBlueprint, conditionOP, thresholdValue }) => ({
      id: uuid(),
      type: TRIGGER_TYPES.CONDITIONAL,
      data: {
        blueprint: sourceBlueprint,
        condition: CONDITION_OPERATORS.find(
          (op) => op.symbolicName === conditionOP
        ),
        trigger: thresholdValue,
      },
    })
  );

  const formattedObj = {
    id: id,
    title: name,
    icon: icon,
    triggers: [...formattedTriggers],
    actions: [...formattedActions],
  };

  const chronicalExists = hours || minutes || seconds;
  if (chronicalExists) {
    const chronicalTrigger = {
      id: uuid(),
      type: TRIGGER_TYPES.CHRONICAL,
      data: {
        hours: hours ? hours : "",
        minutes: minutes ? minutes : "",
        seconds: seconds ? seconds : "",
      },
    };
    formattedObj.triggers.push(chronicalTrigger);
  }

  formattedObj.interpretation = interpret(
    formattedObj.triggers,
    formattedObj.actions,
    INTERPRETATION_MODES.DIVIDED_ARRAY_INTERPRET
  );

  return formattedObj;
};
